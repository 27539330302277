import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import { FC } from 'react';
import { DeepMap, FieldError, FieldValues, UseFormRegister } from 'react-hook-form';

type CheckBoxProps = {
	name: string;
	title: string;
	register: UseFormRegister<FieldValues>;
	errors?: DeepMap<FieldValues, FieldError>;
	disabled?: boolean;
	labelFirst?: boolean;
	style?: any;
	isSwitch?: boolean;
};

const CheckBox: FC<CheckBoxProps> = ({ name, title, errors, register, disabled = false, labelFirst = false, style, isSwitch = false }) => {
	let justifyContent = '';
	if (style?.justifyContent === 'start') {
		justifyContent = 'justify-content-start';
	}
	if (style?.justifyContent === 'end') {
		justifyContent = 'justify-content-end';
	}
	if (style?.justifyContent === 'center') {
		justifyContent = 'justify-content-center';
	}
	if (style?.justifyContent === 'between') {
		justifyContent = 'justify-content-between';
	}
	if (style?.justifyContent === 'around') {
		justifyContent = 'justify-content-around';
	}
	if (style?.justifyContent === 'evenly') {
		justifyContent = 'justify-content-evenly';
	}
	return (
		<div className={clsx('form-check form-check-custom form-check-solid', { 'form-switch': isSwitch }, justifyContent)}>
			{title && labelFirst && (
				<label className="form-check-label fw-bold fs-7 mx-3" htmlFor={name}>
					{title}
				</label>
			)}

			<input id={name} type="checkbox" className={clsx('form-check-input', style)} {...register(name)} disabled={disabled} />

			{title && !labelFirst && (
				<label className="form-check-label fw-bold fs-7 mx-3" htmlFor={name}>
					{title}
				</label>
			)}

			{errors && (
				<ErrorMessage
					errors={errors}
					name={name}
					render={({ message }) => (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">
								<span role="alert">{message}</span>
							</div>
						</div>
					)}
				/>
			)}
		</div>
	);
};

export default CheckBox;
