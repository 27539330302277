import { useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { toAbsoluteUrl } from '../../_metronic/helpers';

const AuthLayout = () => {
	useEffect(() => {
		const root = document.getElementById('root');
		if (root) {
			root.style.height = '100%';
			document.body.classList.add('app-auth-default');
		}

		return () => {
			if (root) {
				root.style.height = 'auto';
			}
		};
	}, []);

	return (
		<div className="d-flex flex-column flex-lg-row flex-column-fluid h-100">
			<div className="d-flex flex-column flex-lg-row-fluid w-lg-50 pt-10 order-1 order-lg-2">
				<div className="d-flex flex-center flex-column flex-lg-row-fluid">
					<div className="w-lg-500px pt-10">
						<Outlet />
					</div>
				</div>
			</div>

			<div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-2 order-lg-1">
				<div className="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">
					<Link to="/" className="mb-2">
						<img alt="Logo" src={toAbsoluteUrl('/media/logos/tabol-logo.svg')} className="h-55px" />
					</Link>

					<h5 className="text-center text-white mb-2 ps-9"><FormattedMessage id="AUTH.LAYOUT.TEXT" /></h5>
				</div>
			</div>
		</div>
	);
};

export { AuthLayout };
