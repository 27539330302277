import { ErrorMessage } from '@hookform/error-message';
import { Control, Controller, DeepMap, FieldError, FieldValues, UseFormResetField } from 'react-hook-form';

import Select, { SelectOption } from './Select';

type Props = {
	control: Control<any>;
	name: string;
	onValueChange?: (value: string) => void;
	resetField?: UseFormResetField<FieldValues>;
	resetInput?: string;

	title?: string;
	options: Array<SelectOption>;
	defaultValue?: SelectOption | Array<SelectOption>;
	onInputChange?: (inputChange: string) => void;
	isLoading?: boolean;
	isDisabled?: boolean;
	isClearable?: boolean;
	isSearchable?: boolean;
	isMulti?: boolean;
	required?: boolean;
	filtersTitle?: boolean;
	responsiveTitle?: boolean;
	horizontalSelect?: boolean;

	errors?: DeepMap<FieldValues, FieldError>;
};

const SelectControl: React.FC<Props> = ({
	control,
	name,
	title,
	options,
	defaultValue,
	isMulti,
	onInputChange,
	isLoading,
	isDisabled,
	isSearchable,
	isClearable,
	onValueChange,
	resetField,
	resetInput,
	errors,
	required,
	filtersTitle,
	responsiveTitle,
	horizontalSelect,
}) => {
	const selectionValue = (selection) => {
		if (isMulti && selection instanceof Array) {
			return selection.map((s) => s.value);
		} else {
			return selection?.value;
		}
	};

	const selected = (value): SelectOption | Array<SelectOption> => {
		onValueChange && onValueChange(value);
		if (isMulti) {
			return options.filter((o) => value?.includes(o.value));
		} else {
			return options.find((o) => o.value === value) || [];
		}
	};

	return (
		<>
			<Controller
				name={name}
				control={control}
				defaultValue={selectionValue(defaultValue)}
				render={({ field: { value, onChange } }) => (
					<Select
						name={name}
						value={selected(value)}
						onChange={(selection) => {
							const val = selectionValue(selection);
							onChange(val);
							resetField && resetInput && resetField(resetInput);
						}}
						title={title}
						options={options}
						defaultValue={defaultValue}
						isMulti={isMulti}
						isDisabled={isDisabled}
						isClearable={isClearable}
						isSearchable={isSearchable}
						onInputChange={onInputChange}
						isLoading={isLoading}
						required={required}
						filtersTitle={filtersTitle}
						responsiveTitle={responsiveTitle}
						horizontalSelect={horizontalSelect}
					/>
				)}
			/>
			{errors && (
				<ErrorMessage
					errors={errors}
					name={name}
					render={({ message }) => (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">
								<span role="alert">{message}</span>
							</div>
						</div>
					)}
				/>
			)}
		</>
	);
};

export default SelectControl;
