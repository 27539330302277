const DEFAULT_PAGE = 1;
const DEFAULT_SIZE = 10;
const API_DEFAULT_SIZE = 10;

const SOCIAL = ['facebook', 'instagram', 'tik tok'];

const BUSINESS_LANGUAGES = [
	{
		code: 'en',
		name: 'English(US)',
		abbr: 'EN',
		flag: '/media/flags/united-states.svg',
	},
	{
		code: 'el',
		name: 'Greek',
		abbr: 'GR',
		flag: '/media/flags/greece.svg',
	},
];

export const PRIMARY_BUSINESS_LANGUAGE = BUSINESS_LANGUAGES[1];

export { API_DEFAULT_SIZE, BUSINESS_LANGUAGES, DEFAULT_PAGE, DEFAULT_SIZE, SOCIAL };
