import { useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import BlockingModalBody from 'src/pages/blocking/BlockingModalBody';

import Modal from '../../components/modal/Modal';
import { ModalContext } from '../../components/modal/useModal';
import ReservationModalBody from '../../pages/reservations/ReservationModalBody/ReservationModalBody';
import { selectModal, setShowModal } from '../../reducers/modalSlice';
import { useDeleteReservationMutation } from '../../reducers/reservationApiSlice';
import { reInitMenu } from '../helpers';
import { Content } from './components/content';
import { HeaderWrapper } from './components/header';
import { ScrollTop } from './components/scroll-top';
import { Sidebar } from './components/sidebar';
import { ToolbarWrapper } from './components/toolbar/ToolbarWrapper';
import { PageDataProvider } from './core';

const MasterLayout = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const intl = useIntl();

	const [deleteReservation] = useDeleteReservationMutation();
	const { show: reservationShowModal, reservationPayload } = useSelector((state) => selectModal(state, 'reservation'));

	const { show: blockingShowModal } = useSelector((state) => selectModal(state, 'blocking'));

	useEffect(() => {
		reInitMenu();
	}, [location.key]);

	const reservationFormRef = useRef();
	const reservationContextValues = useMemo(() => {
		return {
			id: reservationPayload,
			ref: reservationFormRef,
			title: intl.formatMessage({ id: 'MENU.RESERVATIONS' }),
			editMode: !!reservationPayload,
			body: <ReservationModalBody />,
			confirmedAction: (id: any) => deleteReservation(id),
			onSubmitForm: () => (reservationFormRef.current as any).onSubmit(),
			onClose: () => dispatch(setShowModal({ type: 'reservation', show: false })),
		};
	}, [dispatch, reservationPayload]);

	const blockingFormRef = useRef();
	const blockingContextValues = useMemo(() => {
		return {
			ref: blockingFormRef,
			title: intl.formatMessage({ id: 'BLOCKING_HOURS.MODAL.HEADER' }),
			// editMode: true,
			body: <BlockingModalBody />,
			onSubmitForm: () => (blockingFormRef.current as any).onSubmit(),
			onClose: () => dispatch(setShowModal({ type: 'blocking', show: false })),
		};
	}, [dispatch]);

	return (
		<PageDataProvider>
			<div className="d-flex flex-column flex-root app-root" id="kt_app_root">
				<div className="app-page flex-column flex-column-fluid" id="kt_app_page">
					<HeaderWrapper />
					<div className="app-wrapper padding-top-fix flex-column flex-row-fluid" id="kt_app_wrapper">
						<Sidebar />
						<div className="app-main flex-column flex-row-fluid" id="kt_app_main">
							<div className="d-flex flex-column flex-column-fluid">
								<ToolbarWrapper />
								<Content>
									<Outlet />
								</Content>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ModalContext.Provider value={reservationContextValues}>{reservationShowModal && <Modal />}</ModalContext.Provider>
			<ModalContext.Provider value={blockingContextValues}>{blockingShowModal && <Modal />}</ModalContext.Provider>

			<ScrollTop />
		</PageDataProvider>
	);
};

export default MasterLayout;
