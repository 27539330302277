import 'react-datepicker/dist/react-datepicker.css';

import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import React from 'react';
import DatePicker from 'react-datepicker';
import { Control, Controller, DeepMap, FieldError, FieldValues } from 'react-hook-form';

import { KTSVG } from '../../_metronic/helpers';

export type DatePickerProps = {
	control: Control<any>;
	name: string;
	title?: string;
	startDate: Date;
	disabled?: boolean;
	thumbnail?: boolean;
	format?: string;
	filterDate?: (date: Date) => boolean;
	required?: boolean;
	errors?: DeepMap<FieldValues, FieldError>;
	mode?: 'date' | 'time';
	responsiveTitle?: boolean;
	filtersTitle?: boolean;
	horizontalDatepicker?: boolean;
};

export function formatDateDDMMYYY(date) {
	if (!date || date == '') return undefined;
	let day = date.getDate().toString().padStart(2, '0');
	let month = (date.getMonth() + 1).toString().padStart(2, '0');
	let year = date.getFullYear();
	return `${year}-${month}-${day}`;
}

// Format time to HH:mm
export function formatTimeHHMM(date) {
	if (!date || date == '') return undefined;

	let hours = date.getHours().toString().padStart(2, '0');
	let minutes = date.getMinutes().toString().padStart(2, '0');
	return `${hours}:${minutes}`;
}

export const formatDate = (date) => {
	const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
	const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);

	const partsObject = dateTimeFormat.formatToParts(date);
	const parts = partsObject.map((p) => p.value);

	return `${parts[4]}-${parts[0]}-${parts[2]}`;
};

const Datepicker = ({
	control,
	startDate,
	name,
	title,
	disabled = false,
	thumbnail = true,
	filterDate,
	required = false,
	mode,
	errors,
	format,
	responsiveTitle = false,
	filtersTitle = false,
	horizontalDatepicker = false,
}: DatePickerProps) => {
	return (
		<Controller
			name={name}
			control={control}
			defaultValue={startDate}
			render={({ field: { value, onChange, ref, onBlur } }) => {
				return (
					<>
						<div className={clsx({ 'row align-items-center': horizontalDatepicker })}>
							<div className={clsx({ 'col-12 col-sm-3': horizontalDatepicker })}>
								<div
									className={clsx('pb-3 fs-7 fw-bold', {
										'pt-5 pt-sm-0': responsiveTitle,
										'py-3': filtersTitle,
										required: required,
									})}>
									{title}
								</div>
							</div>
							<div className={clsx({ 'col-12 col-sm-9': horizontalDatepicker })}>
								<div className="input-group icon-input">
									{thumbnail && (
										<div className="custom-input-icon icon-left d-flex align-items-center fw-bold">
											<KTSVG path="/media/icons/duotune/general/gen014.svg" className="svg-icon-muted svg-icon-2x" />
										</div>
									)}
									<DatePicker
										showTimeSelect={mode == 'time'}
										showTimeSelectOnly={mode == 'time'}
										dateFormat={!mode ? 'dd MMMM yyyy' : mode == 'date' ? format || 'dd MMMM yyyy' : format || 'HH:mm'}
										className="form-control fs-6 p-4"
										selected={new Date(value)}
										onChange={(dateChange) => onChange(dateChange)}
										ref={ref}
										onBlur={onBlur}
										placeholderText={'mm/dd/yyyy'}
										filterDate={filterDate}
										disabled={disabled}
									/>
								</div>
							</div>
							{errors && (
								<ErrorMessage
									errors={errors}
									name={name}
									render={({ message }) => (
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">
												<span role="alert">{message}</span>
											</div>
										</div>
									)}
								/>
							)}
						</div>
					</>
				);
			}}
		/>
	);
};

export default Datepicker;
