import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { toAbsoluteUrl } from '../../../_metronic/helpers';

const Error500 = () => {
	return (
		<>
			{/* begin::Title */}
			<h1 className="fw-bolder fs-2qx text-gray-900 mb-4"><FormattedMessage id="ERROR.500.TITLE" /></h1>
			{/* end::Title */}

			{/* begin::Text */}
			<div className="fw-semibold fs-6 text-gray-500 mb-7"><FormattedMessage id="ERROR.500.TEXT" /></div>
			{/* end::Text */}

			{/* begin::Illustration */}
			<div className="mb-11">
				<img src={toAbsoluteUrl('/media/auth/500-error.png')} className="mw-100 mh-300px theme-light-show" alt="" />
				<img src={toAbsoluteUrl('/media/auth/500-error-dark.png')} className="mw-100 mh-300px theme-dark-show" alt="" />
			</div>
			{/* end::Illustration */}

			{/* begin::Link */}
			<div className="mb-0">
				<Link to="/dashboard" className="btn btn-sm btn-primary">
                    <FormattedMessage id="ERROR.MSG.RETURN" />
				</Link>
			</div>
			{/* end::Link */}
		</>
	);
};

export { Error500 };
