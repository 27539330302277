import { BUSINESS_LANGUAGES } from './constants';

const fillSupportedLangs = (obj) => {
	const res = [];

	BUSINESS_LANGUAGES.forEach((lng) => {
		const newObj = { ...obj };
		newObj.lng = lng.code;
		res.push(newObj);
	});

	return res;
};

const isEmptyObj = (obj) => {
	if (obj === undefined || obj === null) return true;

	return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
};

export { fillSupportedLangs, isEmptyObj };
