import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Area, KeyValue, Reservation, Table } from 'src/pages/reservations/host-view/types';
import { HostViewEnum, StatusEnum } from 'src/pages/reservations/host-view/types/enums';

export enum HostViewFiltersEnum {
	Date = 'date',
	Time = 'time',
	Location = 'location',
}

export interface HostViewFilters {
	[HostViewFiltersEnum.Date]: KeyValue | null;
	[HostViewFiltersEnum.Time]: KeyValue | null;
	[HostViewFiltersEnum.Location]: KeyValue | null;
}

interface HostViewState {
	width: number;
	openSidebar: boolean;
	filters: HostViewFilters;
	activeTab: HostViewEnum;
	activeArea: string | null;
	status: StatusEnum | null;
	areas: Area[];
	reservations: Reservation[];
}

const initialState: HostViewState = {
	status: null,
	activeArea: null,
	openSidebar: false,
	width: window.innerWidth,
	activeTab: HostViewEnum.Reservation,
	filters: {
		[HostViewFiltersEnum.Date]: null,
		[HostViewFiltersEnum.Time]: null,
		[HostViewFiltersEnum.Location]: null,
	},
	areas: [],
	reservations: [],
};

const hostViewSlice = createSlice({
	name: 'hostView',
	initialState,
	reducers: {
		setSidebarOpen: (state) => {
			state.openSidebar = !state.openSidebar;
		},
		setWidth: (state, action: PayloadAction<number>) => {
			state.width = action.payload;
		},
		setStatus: (state, action: PayloadAction<StatusEnum | null>) => {
			state.status = action.payload;
		},
		setActiveArea: (state, action: PayloadAction<string | null>) => {
			state.activeArea = action.payload;
		},
		setActiveTab: (state, action: PayloadAction<HostViewEnum>) => {
			state.activeTab = action.payload;
		},
		setFilters: (state, action: PayloadAction<HostViewFilters>) => {
			state.filters = action.payload;
		},
		setAreas: (state, action: PayloadAction<Area[]>) => {
			state.areas = action.payload;
		},
		setReservations: (state, action: PayloadAction<Reservation[]>) => {
			state.reservations = action.payload;
		},
	},
});

export const { setWidth, setSidebarOpen, setActiveArea, setActiveTab, setStatus, setFilters, setAreas, setReservations } = hostViewSlice.actions;

export const selectWidth = (state: { hostView: HostViewState }) => state.hostView.width;
export const selectAreas = (state: { hostView: HostViewState }) => state.hostView.areas;
export const selectStatus = (state: { hostView: HostViewState }) => state.hostView.status;
export const selectFilters = (state: { hostView: HostViewState }) => state.hostView.filters;
export const selectActiveTab = (state: { hostView: HostViewState }) => state.hostView.activeTab;
export const selectActiveArea = (state: { hostView: HostViewState }) => state.hostView.activeArea;
export const selectOpenSidebar = (state: { hostView: HostViewState }) => state.hostView.openSidebar;
export const selectReservations = (state: { hostView: HostViewState }) => state.hostView.reservations;

const selectReservedTablesMap = createSelector([selectReservations], (reservations) => {
	const reservedTablesMap = new Map();

	reservations.forEach((res) => {
		res.tables.forEach((table) => {
			reservedTablesMap.set(table._id, res);
		});
	});

	return reservedTablesMap;
});

export const selectTables = (disableFilters?: boolean, disableArea?: boolean) =>
	createSelector([selectAreas, selectActiveArea, selectStatus, selectReservedTablesMap], (areas, activeArea, activeStatus, reservedTablesMap) => {
		const updateTableStatus = (table) => {
			const res = reservedTablesMap.get(table._id);

			return {
				...table,
				date: res ? res.date : null,
				res_id: res ? res?._id : null,
				guest: res ? `${res.host.fname} ${res.host.lname}` : null,
				people: res ? res.people : `${table.min_seats}-${table.max_seats}`,
				status: res ? StatusEnum.Reserved : StatusEnum.Available, // Need modification for occupied status
			};
		};

		const filterByActiveStatus = (table) => {
			if (!activeStatus || disableFilters) {
				return true; // Show all tables if activeStatus is null & disableFilters is true
			}

			return table.status === activeStatus;
		};

		const sortByStatus = (a, b) => {
			const statusOrder = {
				[StatusEnum.Available]: 1,
				[StatusEnum.Reserved]: 2,
				[StatusEnum.Occupied]: 3,
			};
			return statusOrder[a.status] - statusOrder[b.status];
		};

		if (activeArea && !disableArea) {
			return (
				areas
					?.find((area) => area._id === activeArea)
					?.tables.map(updateTableStatus)
					.filter(filterByActiveStatus)
					.sort(sortByStatus) || []
			);
		}

		return areas.reduce((acc: Area[], area: Area) => {
			const updatedTables = area.tables.map(updateTableStatus).filter(filterByActiveStatus).sort(sortByStatus);
			return [...acc, ...updatedTables];
		}, []);
	});

export const selectStatusDetails = createSelector([selectTables(true, false)], (tables: Table[]) => {
	const details = {
		available: 0,
		reserved: 0,
		occupied: 0,
	};

	tables.forEach((table) => {
		if (table.status === StatusEnum.Available) {
			details.available += 1;
		} else if (table.status === StatusEnum.Reserved) {
			details.reserved += 1;
		} else if (table.status === StatusEnum.Occupied) {
			details.occupied += 1;
		}
	});

	return details;
});

export const selectOverviewDetails = createSelector([selectTables(true, true)], (tables: Table[]) => {
	const details = {
		wait: 30,
		guests: 0,
		availability: 0,
		tables: tables.length ?? 0,
	};

	if (details.tables > 0) {
		const totalAvailableTables = tables.filter((table) => table.status === StatusEnum.Available).length;
		details.availability = Math.round((totalAvailableTables / details.tables) * 100);
	}

	tables.forEach((table) => {
		if (table.status === StatusEnum.Reserved || table.status === StatusEnum.Occupied) {
			details.guests += table.people || 0;
		}
	});

	return details;
});

export default hostViewSlice.reducer;
