/* eslint-disable react/no-array-index-key */
import { useState } from 'react';

import TableContext from '../../../components/table/context';
import Table from '../../../components/table/Table';
import { formatMobileNumber } from '../../../helpers/phones';

const ReservationTable = ({ data, headers, actions = [] }) => {
	const [sort, setSort] = useState({ key: '', dir: 'asc' });

	const sortHandler = (name) => {
		setSort({
			key: name,
			// eslint-disable-next-line no-nested-ternary
			dir: sort.key !== name ? 'asc' : sort.dir === 'asc' ? 'desc' : 'asc',
		});
	};

	// eslint-disable-next-line react/jsx-no-constructed-context-values
	const contextValue = {
		sort,
		onSort: (name) => sortHandler(name),
	};

	return (
		<TableContext.Provider value={contextValue}>
			<div>
				<div className="card-toolbar">
					<div className="py-6">
						<Table.Search />
					</div>
				</div>
				<div className="card-body py-2">
					<Table>
						<Table.Header>
							{headers.map((item) => (
								<Table.Column key={item.name}>{item.name}</Table.Column>
							))}
						</Table.Header>

						<Table.Body>
							{data.map((item) => (
								<Table.Row key={item.id}>
									<Table.Cell>{item.fullname}</Table.Cell>
									<Table.Cell>{item.email}</Table.Cell>
									<Table.Cell>{formatMobileNumber(item.phoneNumber)}</Table.Cell>

									{actions.length > 0 && (
										<Table.CellActions>
											{actions.map((a) => (
												<Table.CellActionItems key={a.name} id={item.id}>
													{a}
												</Table.CellActionItems>
											))}
										</Table.CellActions>
									)}
								</Table.Row>
							))}
						</Table.Body>
					</Table>
				</div>
			</div>
		</TableContext.Provider>
	);
};

export default ReservationTable;
