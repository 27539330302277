import { createSlice } from '@reduxjs/toolkit';

const initialToolbarState = {
	tollbarActions: [],
};

const toolbarSlice = createSlice({
	name: 'toolbar',
	initialState: initialToolbarState,
	reducers: {
		setToolbarActions: (state, action) => {
			state.tollbarActions = action.payload;
		},
	},
});

const getToolbarActions = ({ toolbar: { tollbarActions } }) => tollbarActions;

const { setToolbarActions } = toolbarSlice.actions;

export { getToolbarActions, setToolbarActions };

export default toolbarSlice.reducer;
