/* eslint-disable no-prototype-builtins */
import clsx from 'clsx';
import { Children, useContext } from 'react';

import TableContext from './context';

let lastChildField = null;

const TableHeader = ({ children }) => {
	const childrenArray = Children.toArray(children);
	lastChildField = childrenArray[childrenArray.length - 1]?.props?.field;

	return (
		<thead>
			<tr className="fw-bold text-muted">
				<TableColumnCheckbox />
				{children}
			</tr>
		</thead>
	);
};

const TableColumnCheckbox = () => {
	const { selectable, items, selectedRows, selectRows } = useContext(TableContext);
	if (!selectable) return;

	const rows = items.map(({ _id }) => _id);
	const uncheckedRows = rows.filter((rowId) => !selectedRows.includes(rowId));
	const checked = rows.length !== uncheckedRows.length;
	const checkedIndeterminate = checked && uncheckedRows.length > 0;

	const handleChange = () => {
		if (checkedIndeterminate) {
			selectRows(uncheckedRows);
		} else {
			selectRows(rows);
		}
	};

	return (
		<th className="w-25px border-0 d-flex">
			<label className="checkbox-container fs-7">
				<input type="checkbox" checked={checked} onChange={handleChange} />
				<span className={`checkmark ${checkedIndeterminate ? ' select-items' : ''}`} /> {/* select-items */}
			</label>
		</th>
	);
};

const TableColumn = ({ children, field, sortable, hidden }) => {
	const { tableControls = {}, onTableControlsChange } = useContext(TableContext);
	const { sort } = tableControls;
	if (hidden) return;

	const handleSort = (field) => {
		if (!sortable) return;
		let dir = 'asc';
		if (sort && sort[field] && sort[field] === dir) {
			dir = 'desc';
		}

		const newSort = { ...sort, [field]: dir };
		onTableControlsChange({ ...tableControls, sort: newSort });
	};

	const sortableItem = sort && sort.hasOwnProperty(field);

	const arrow = sortable && (
		<span className="ms-2">
			{sort && sort[field] === 'asc' && sortableItem ? <i className="fas fa-angle-up" /> : <i className="fas fa-angle-down" />}
		</span>
	);

	return (
		<th
			className={clsx(
				'min-w-130px',
				{ 'cursor-pointer': sortable },
				{ 'text-primary': sortableItem },
				{ 'text-end': lastChildField === field }
			)}
			onClick={() => handleSort(field)}
		>
			{children}
			{arrow}
		</th>
	);
};

export { TableColumn, TableColumnCheckbox, TableHeader };
