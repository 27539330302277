import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import Divider from 'src/components/utils/Divider';

import { setShowModal } from '../../../../../reducers/modalSlice';
import { KTSVG } from '../../../../helpers';
import { HeaderStoreMenu, HeaderUser, HeaderUserMenu } from '../../../../partials';
import { MenuButton } from './MenuButton';

export function MenuInner() {
	const dispatch = useDispatch();
	const intl = useIntl();
	const itemClass = 'ms-1 ms-lg-3';
	const btnClass = 'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px';
	const btnIconClass = 'svg-icon-1';

	return (
		<>
			<MenuButton
				title={intl.formatMessage({ id: 'HEADER.MENU.BLOCKING' })}
				classname="py-3 btn white-button fw-bold"
				onClick={() => dispatch(setShowModal({ type: 'blocking' }))}
			/>
			<MenuButton
				title={intl.formatMessage({ id: 'HEADER.MENU.NEW_RESERVATION' })}
				classname="btn btn-primary"
				onClick={() => dispatch(setShowModal({ type: 'reservation' }))}
			/>
			<div className="d-sm-none">
				<Divider />
			</div>

			<div className="d-flex d-sm-none justify-content-evenly">
				<div className={clsx('app-navbar-item', itemClass)}>
					<div
						data-kt-menu-trigger="{default: 'click'}"
						data-kt-menu-attach="parent"
						data-kt-menu-placement="bottom-end"
						className={btnClass}
					>
						<KTSVG path="/media/icons/duotune/ecommerce/ecm004.svg" className={btnIconClass} />
					</div>
					<HeaderStoreMenu />
				</div>
				<div className={clsx('app-navbar-item', itemClass)}>
					<HeaderUser />
					<HeaderUserMenu />
				</div>
			</div>
		</>
	);
}
