import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';

import { FC, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useGetTranslationsQuery } from 'src/app/services/apiSlice';

import { WithChildren } from '../helpers';
import enMessages from './messages/en.json';
import { I18nContext, I18nContextDefaultProps, useLang } from './Metronici18n';

let allMessages = {
	en: enMessages,
};

export type Translations = {
	lang: String;
	entries: Object;
};

export type StoredLanguages = {
	lastFetched: number; //milliseconds
	ttl: number;
	translations: Set<Translations>;
};

const getTranslationsFromStorage = (): StoredLanguages | null => {
	let storedLanguages = localStorage.getItem('translations');

	if (storedLanguages) {
		return JSON.parse(storedLanguages);
	}

	return null;
};

const shouldFetchTranslations = (storedLanguages: StoredLanguages | null = null): boolean => {
	let _trans = storedLanguages || getTranslationsFromStorage();
	if (!_trans) return true;
	const ttl = 10000;
	const now = Date.now();
	const diff = now - _trans.lastFetched;
	return diff > ttl;
};

const I18nProvider: FC<WithChildren> = ({ children }) => {
	const [locale, setLocale] = useState('en');
	let translations: StoredLanguages | null;
	const { data, isLoading } = useGetTranslationsQuery(undefined, { skip: !shouldFetchTranslations() });

	translations = getTranslationsFromStorage();
	if (translations && !isLoading) {
		allMessages['el'] = translations.translations;
	}
	const messages = allMessages[locale];

	let props = I18nContextDefaultProps;
	props.changeLanguage = setLocale;

	return (
		<I18nContext.Provider value={I18nContextDefaultProps}>
			<IntlProvider locale={locale} messages={messages}>
				{children}
			</IntlProvider>
		</I18nContext.Provider>
	);
};

export { I18nProvider };
