import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { KTSVG } from '../../_metronic/helpers';
import { useUploadImageMutation } from '../../reducers/fileApiSlice';

type Props = {
	onUpload?: (media: any) => void;
	disabled?: boolean;
};

const File: React.FC<Props> = ({ onUpload, disabled = false }) => {
	const [uploadImage] = useUploadImageMutation();
	const [media, setMedia] = useState<any>([]);

	const handleChange = async (e) => {
		const fileInput = e.target;
		const file = fileInput.files[0];

		const formData = new FormData();
		formData.append('image', file);

		const response = await uploadImage(formData);
		if ('data' in response) {
			onUpload && onUpload(response.data.media);
			media.push(response.data.media);

			setMedia(media);
		}
	};
	return (
		<div className="uploader-container p-4 fs-5 fw-bold">
			<div className="uploader-wrapper">
				<input type="file" id="file" className="custom-checkbox" accept="image/jpg, image/jpeg" onChange={handleChange} disabled={disabled} />
				<label htmlFor="file" className="uploader">
					<KTSVG className="svg-icon-4x svg-icon-primary" path="/media/icons/duotune/files/fil009.svg" />
					<div className="uploader-text">
						<span className="fs-bold"><FormattedMessage id="FILES.UPLOAD" /></span>
						<span className="fs-7 text-primary"><FormattedMessage id="FILES.DRAG.DROP" /></span>
						{media.map((m) => (
							<div key={m._id} className="d-inline-flex align-items-center">
								<span className="fs-7 text-primary">{m.title}</span>
							</div>
						))}
					</div>
				</label>
			</div>
		</div>
	);
};

export default File;
