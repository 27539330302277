import { createSelector, createSlice } from '@reduxjs/toolkit';

import { getSessionStorage, setSessionStorage } from '../../common/storage';

const initialState = {
	businesses: [],
	activeBusiness: JSON.parse(getSessionStorage('act_bsn')) || {},
	activeStore: JSON.parse(getSessionStorage('act_str')) || {},
};

const businessSlice = createSlice({
	name: 'business',
	initialState,
	reducers: {
		setBusinessState: (state, action) => {
			const businesses = action.payload;

			let actBsn = state.activeBusiness;
			let actStr = state.activeStore;

			if (businesses[0]) {
				actBsn = { _id: businesses[0]._id, title: businesses[0].title, logo: businesses[0].logo };
			}
			if (businesses[0] && businesses[0].stores && businesses[0].stores[0]) {
				actStr = { _id: businesses[0].stores[0]._id, title: businesses[0].stores[0].title };
			}

			state.businesses = businesses;
			state.activeBusiness = actBsn;
			state.activeStore = actStr;
			setSessionStorage('act_bsn', JSON.stringify(actBsn));
			setSessionStorage('act_str', JSON.stringify(actStr));
		},
		setActiveBusiness: (state, action) => {
			const { business } = action.payload;

			if (state.activeBusiness._id !== business._id) {
				state.activeBusiness = business;
				setSessionStorage('act_bsn', JSON.stringify(business));
			}
		},
		setActiveBusinessStore: (state, action) => {
			const { store, business } = action.payload;

			if (state.activeBusiness._id !== business._id) {
				state.activeBusiness = business;
				setSessionStorage('act_bsn', JSON.stringify(business));
			}

			state.activeStore = store;
			setSessionStorage('act_str', JSON.stringify(store));
		},
		setActiveStore: (state, action) => {
			const { _id, title } = action.payload;

			state.activeStore = { _id, title };
			setSessionStorage('act_str', JSON.stringify({ _id, title }));
		},
	},
});

export const { setBusinessState, setActiveStore, setActiveBusinessStore, setActiveBusiness } = businessSlice.actions;

export const selectBusinesses = ({ business }) => business.businesses;
export const selectActiveBusiness = ({ business }) => business.activeBusiness;
export const selectActiveStore = ({ business }) => business.activeStore;

export const selectMemoBusinessStores = (bid) =>
	createSelector([selectBusinesses], (businesses) => {
		const selectedBusiness = businesses.find((b) => b._id === bid);

		return selectedBusiness?.stores || [];
	});

export default businessSlice.reducer;
