import { useDispatch, useSelector } from 'react-redux';

import useModal from './useModal';

const ModalBody = () => {
	const { body } = useModal();
	const modal = (state) => state;
	return <div className="modal-body px-lg-10">{body}</div>;
};

export default ModalBody;
