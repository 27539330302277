import { useCallback, useMemo, useState } from 'react';

import { SelectOption } from './Select';

type Props = {
	useQuery?: any;
	responseOptions?: {
		dataName: string;
		path: object;
		idField: string;
		labelField: string;
		skip: boolean;
		searchable: boolean;
	};
};

// Used to request select options from api
const useSelectQuery = ({
	responseOptions,
	useQuery,
}: Props): { options: Array<SelectOption>; isLoading: boolean; isSuccess: boolean; onInputChange: (input: string) => void } => {
	const [search, setSearch] = useState('');

	const { dataName, path, idField = '', labelField = '', skip, searchable } = responseOptions || {};

	const args = {
		query: {
			search,
		},
		path: path,
	};

	const skpiRequest = skip || (search.length > 0 && search.length < 3);
	const { data, isLoading, isSuccess } = useQuery(args, { skip: skpiRequest });

	const options = useMemo(() => {
		if (responseOptions) {
			if (isLoading) return [];
			const dataList = getDataListByPath(data, dataName);
			return dataList
				.filter((d) => d[labelField].toLowerCase().includes(search)) // search localy
				.map((d) => ({ value: d[idField], label: d[labelField] }));
		}
		return [];
	}, [data, isLoading, search]);

	const onInputChange = useCallback((input) => searchable && setSearch(input), [searchable]);
	return { options, isLoading, isSuccess, onInputChange };
};

function getDataListByPath(object, field) {
	// Initialize a variable to store the current value
	let value = object;

	if (!value) return [];
	if (!field || field.length === 0) return value;

	const keys = field.split('.');
	// Iterate through the keys and check for undefined at each level
	for (const key of keys) {
		if (value && typeof value === 'object' && key in value) {
			// If the value is not undefined and the key exists in the current object,
			// update the value for the next iteration
			value = value[key];
		} else {
			// If the value is undefined or the key doesn't exist, set value to undefined and break
			value = [];
			break;
		}
	}
	return value;
}

export default useSelectQuery;
