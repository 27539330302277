import clsx from 'clsx';
import { useContext } from 'react';

import TableContext from './context';

const TablePagination = () => {
	const {
		showing,
		paging: { totalPages },
	} = useContext(TableContext);

	if (totalPages <= 1) return null;

	return (
		<div className="d-flex flex-stack flex-wrap">
			<div className="fs-6 fw-bold text-gray-700">
				Showing {showing.from} to {showing.to} of {showing.total} entries
			</div>
			<TablePagesNavigation />
		</div>
	);
};

const TablePagesNavigation = () => {
	const {
		paging: { currentPage, totalPages },
	} = useContext(TableContext);

	return (
		<ul className="pagination justify-content-end cursor-pointer z-index-0">
			{currentPage > 1 && <PageArrowItem direction="previous" />}

			<PageItems />

			{currentPage < totalPages && <PageArrowItem direction="next" />}
		</ul>
	);
};

const PageItems = () => {
	const {
		paging: { currentPage, totalPages },
	} = useContext(TableContext);

	const pageItems = [];
	const [firstPage, lastPage] = [1, totalPages];

	const startIndex = Math.max(Math.min(currentPage - 1, totalPages - 2), firstPage);
	const endIndex = Math.min(Math.max(currentPage + 1, firstPage + 2), lastPage);

	if (startIndex !== 1 && currentPage - 2 >= firstPage) pageItems.push(<PageItem key={firstPage} page={firstPage} />);

	if (startIndex > 2) pageItems.push(<PageDotItem key={`dot-${firstPage}`} />);
	for (let i = startIndex; i <= endIndex; i++) {
		pageItems.push(<PageItem key={i} page={i} />);
	}

	if (endIndex < totalPages - 1) pageItems.push(<PageDotItem key={`dot-${lastPage}`} />);

	if (endIndex !== totalPages && lastPage > firstPage && currentPage + 2 <= totalPages) pageItems.push(<PageItem key={lastPage} page={lastPage} />);

	return pageItems;
};

const PageItem = ({ page }) => {
	const {
		onTableControlsChange,
		tableControls,
		paging: { currentPage, actions },
		querySize,
	} = useContext(TableContext);

	const handlePageChange = (newPage) => {
		actions.goTo(newPage);
		let queryPage = newPage % querySize || querySize;
		onTableControlsChange({ ...tableControls, page: queryPage });
	};

	return (
		<li key={page} className={clsx('page-item', { active: currentPage === page })} onClick={() => handlePageChange(page)}>
			<span className="page-link">{page}</span>
		</li>
	);
};

const PageDotItem = () => {
	return (
		<li className="page-item">
			<span className="page-link">...</span>
		</li>
	);
};

const PageArrowItem = ({ direction }) => {
	const {
		onTableControlsChange,
		tableControls,
		paging: { currentPage, actions },
		querySize,
	} = useContext(TableContext);

	const handlePageChange = (newPage) => {
		actions.goTo(newPage);
		let queryPage = newPage % querySize || querySize;
		onTableControlsChange({ ...tableControls, page: queryPage });
	};

	const page = direction === 'next' ? currentPage + 1 : currentPage - 1;

	return (
		<li className={`page-item ${direction}`} onClick={() => handlePageChange(page)}>
			<span className="page-link">
				<i className={`${direction}`} />
			</span>
		</li>
	);
};

export { TablePagination };
