import { ErrorMessage } from '@hookform/error-message';
import { Control, Controller, DeepMap, FieldError, FieldValues, UseFormResetField } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectActiveBusiness, selectBusinesses, selectMemoBusinessStores } from 'src/reducers/business/businessSlice';

import { Select } from '..';
import { SelectOption } from '../Select';

type Props = {
	control: Control<any>;
	name: string;
	onValueChange?: (value: string) => void;
	resetField?: UseFormResetField<FieldValues>;
	resetInput?: string;

	role: string;
	onInputChange?: (inputChange: string) => void;
	isDisabled?: boolean;

	errors?: DeepMap<FieldValues, FieldError>;
};

const SelectBusiness: React.FC<Props> = ({ control, name, errors, role, onValueChange, resetField, resetInput, isDisabled = false }) => {
	const { _id: bid } = useSelector(selectActiveBusiness);
	const storesOptions = useSelector(selectMemoBusinessStores(bid)).map(({ _id, title }) => ({ value: _id, label: title }));
	const businessesOptions = useSelector(selectBusinesses).map(({ _id, title }) => ({ value: _id, label: title }));

	// const activeBusiness = businessesOptions.find((option) => option.value === bid);
	// const defaultBusinessOption = [activeBusiness];

	const selectionValueBusiness = (selection) => {
		return selection.map((s) => ({ _id: s.value }));
	};

	const selectionValueStore = (selection) => {
		return [{ _id: bid, stores: selection.map((s) => s.value) }];
	};

	const selectedBusinesses = (businesses): SelectOption | Array<SelectOption> => {
		onValueChange && onValueChange(businesses);

		let businessesIds = businesses.map((b) => b['_id']);
		return businessesOptions.filter((o) => businessesIds?.includes(o.value));
	};

	const selectedStores = (businesses): SelectOption | Array<SelectOption> => {
		onValueChange && onValueChange(businesses);

		let storesIds = businesses[0]?.stores;
		return storesOptions.filter((o) => storesIds?.includes(o.value));
	};

	const intl = useIntl();

	return (
		<>
			<Controller
				name={name}
				control={control}
				defaultValue={[]}
				render={({ field: { value, onChange } }) => {
					if (['admin', 'accountant'].includes(role))
						return (
							<Select
								value={selectedBusinesses(value)}
								onChange={(selection) => {
									// const val = selectionValueBusiness(selection);
									onChange(selectionValueBusiness(selection));
									// onValueChange && onValueChange(val);
									resetField && resetInput && resetField(resetInput);
								}}
								title={intl.formatMessage({ id: 'USER.MODAL.ACCOUNT.TAB.ORGANIZATION' })}
								options={businessesOptions}
								// defaultValue={selectionValue2(defaultBusinessOption)}
								isDisabled={isDisabled}
								isMulti={true}
								isClearable={true}
								isSearchable={true}
							/>
						);
					else if (['host', 'store_manager', 'employee'].includes(role))
						return (
							<Select
								value={selectedStores(value)}
								onChange={(selection) => {
									onChange(selectionValueStore(selection));
									// onValueChange && onValueChange(val);
									resetField && resetInput && resetField(resetInput);
								}}
								title={intl.formatMessage({ id: 'USER.MODAL.ACCOUNT.TAB.LOCATION' })}
								options={storesOptions}
								isDisabled={isDisabled}
								isMulti={true}
								isClearable={true}
								isSearchable={true}
							/>
						);
					else return <></>;
				}}
			/>
			{errors && (
				<ErrorMessage
					errors={errors}
					name={name}
					render={({ message }) => (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">
								<span role="alert">{message}</span>
							</div>
						</div>
					)}
				/>
			)}
		</>
	);
};

export default SelectBusiness;
