const prepareURL = (rawUrl, args) => {
	let url = rawUrl;

	if (args?.path) {
		url = parsePathParams(url, args.path);
	}

	if (args?.query) {
		url += parseQueryParams(args.query);
	}
	return url;
};

/**
 * Parses the path parameters in the given URL based on the provided path object.
 *
 * @param {string} rawUrl - The raw URL containing path parameters.
 * @param {object} path - The path object containing the parameter names and their corresponding values.
 * @returns {string} - The URL with the path parameters replaced by their corresponding values.
 *
 * @example
 * // Input
 * const rawUrl = 'https://example.com/users/{id}/sessions/{sid}';
 * const path = { id: '123', sid: '456' };
 *
 * // Output
 * const parsedUrl = 'https://example.com/users/123/sessions/456';
 */
const parsePathParams = (rawUrl, path) => {
	let url = rawUrl;

	const pathParamsArr = Object.keys(path);
	pathParamsArr.forEach((p) => {
		if (url.includes(p)) {
			url = url.replace(`{${p}}`, path[p]);
		}
	});

	return url;
};

/**
 * Parses the query parameters object into a string representation.
 * @param query - The query parameters object.
 * @returns The query parameters string.
 *
 * @example
 * const query = {
 *   sort: {
 *     field1: 'asc',
 *     field2: 'desc'
 *   },
 *   filter: 'active'
 * };
 *
 * const queryParams = parseQueryParams(query);
 * Output: "?sort[0][field]=field1&sort[0][order]=asc&sort[1][field]=field2&sort[1][order]=desc&filter=active"
 */
export const parseQueryParams = (query) => {
	let queryParams = '';

	if (query && typeof query === 'object') {
		const queryParamsArr = Object.keys(query);
		if (queryParamsArr.length === 0) return queryParams;

		const [querySymbol, addSymbol, equalSymbol] = ['?', '&', '='];
		let currParam = 1;

		queryParamsArr.forEach((param) => {
			// if parammeter value is empty, ignore it
			if (!query[param]) {
				return;
			}

			let queryParam = '';
			if (param === 'sort') {
				let sortIndex = 0;
				Object.keys(query[param]).forEach((field) => {
					if (sortIndex > 0) queryParam += addSymbol;

					const sortKey = `sort[${sortIndex}][field]`;
					const sortOrder = `sort[${sortIndex}][order]`;

					queryParam += encodeURIComponent(sortKey) + equalSymbol + encodeURIComponent(field.toLowerCase()) + addSymbol;
					queryParam += encodeURIComponent(sortOrder) + equalSymbol + encodeURIComponent(query[param][field]);

					sortIndex++;
				});
			} else if (Array.isArray(query[param])) {
				query[param].forEach((value, index) => {
					if (index > 0) queryParam += addSymbol;
					queryParam += encodeURIComponent(`${param}`) + '[]' + equalSymbol + value;
				});
			} else {
				queryParam = encodeURIComponent(param) + equalSymbol + encodeURIComponent(query[param]);
			}

			if (currParam > 1) {
				queryParams += addSymbol;
			} else {
				queryParams += querySymbol;
			}

			queryParams += queryParam;
			currParam++;
		});
	}
	return queryParams;
};
export default prepareURL;
