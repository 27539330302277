import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { CheckBox, Datepicker, SelectControl } from 'src/components/input';
import { formatDate } from 'src/components/input/Datepicker';
import useModal from 'src/components/modal/useModal';
import Divider from 'src/components/utils/Divider';
import Label from 'src/components/utils/Label';

import { devMode } from '../../helpers/utils';
import BlockingItem from './BlockingItem';
import { blockingData, blockingHours } from './dummyData';
import { BlockingHoursItem, initialValues, validationSchema } from './schema';

const BlockingModalBody = () => {
	const { ref } = useModal();
	const intl = useIntl();

	const [areaError, setAreaError] = useState<boolean>(false);
	const [data, setData] = useState<BlockingHoursItem[]>(blockingData);
	const [editedItem, setEditedItem] = useState<string | number | null>(null);

	const nextDate = new Date();
	nextDate.setDate(nextDate.getDate() + 1);

	const {
		watch,
		reset,
		control,
		register,
		getValues,
		handleSubmit,
		formState: { errors },
	} = useForm<FieldValues>({
		defaultValues: initialValues,
		mode: 'onSubmit',
		resolver: yupResolver(validationSchema),
	});

	const selectedDate = watch('date');
	const selectedTabol = watch('tabol');
	const selectedWidget = watch('widget');
	const selectedInStore = watch('inStore');
	const selectedStartTime = watch('startTime');

	const handleDelete = (id: string | number) => {
		setData((prevBlockingHours) => prevBlockingHours.filter((pbh) => pbh.id !== id));
	};

	const handleEdit = (id: string | number) => {
		const selectedItem = data.find((bh) => bh.id === id);
		if (selectedItem) {
			reset(selectedItem);
			setEditedItem(id);
		}
	};

	const handleSave = (id: string | number) => {
		const updatedItem = {
			id,
			date: getValues('date'),
			startTime: getValues('startTime'),
			endTime: getValues('endTime'),
			tabol: getValues('tabol'),
			inStore: getValues('inStore'),
			widget: getValues('widget'),
		};

		setData((prevBlockingHours) => {
			return prevBlockingHours.map((pbh) => {
				if (pbh.id === id) return updatedItem;
				return pbh;
			});
		});

		setEditedItem(null);
		reset(initialValues);
	};

	useImperativeHandle(ref, () => ({
		onSubmit: () => handleSubmit(handleOnSubmit)(),
	}));

	const handleOnSubmit = (inputs) => {
		const { date, startTime, endTime, tabol, inStore, widget } = inputs;

		if (!tabol && !inStore && !widget) setAreaError(true);

		setData([
			...data,
			{
				id: Math.floor(Math.random() * (100 - 4 + 1)) + 4,
				date,
				startTime,
				endTime,
				tabol,
				inStore,
				widget,
			},
		]);

		reset(initialValues);
		// onClose();
	};

	const datePickerRange = (date: Date) => {
		const currentDate = new Date();
		const oneMonthAhead = new Date(currentDate);
		oneMonthAhead.setMonth(oneMonthAhead.getMonth() + 1);

		return formatDate(date) >= formatDate(currentDate) && date.getTime() <= oneMonthAhead.getTime();
	};

	const currentBlocked = useMemo(() => {
		return data.filter((item) => formatDate(item.date) === formatDate(selectedDate));
	}, [data, selectedDate]);

	useEffect(() => {
		if (areaError && (selectedTabol || selectedInStore || selectedWidget)) {
			setAreaError(false);
		}
	}, [selectedTabol, selectedInStore, selectedWidget, setAreaError]);

	return (
		<div className="modal-content">
			<form ref={ref}>
				<Label title={intl.formatMessage({ id: 'BLOCKING_HOURS.MODAL.BODY.DATE' })} />

				<div className="row">
					<div className="col-12">
						<Datepicker
							control={control}
							title={intl.formatMessage({ id: 'BLOCKING_HOURS.MODAL.BODY.DATE' })}
							name="date"
							startDate={new Date()}
							filterDate={datePickerRange}
							errors={errors}
							required
						/>
					</div>
				</div>

				<Divider />

				{currentBlocked.length > 0 && (
					<>
						<Label title={intl.formatMessage({ id: 'BLOCKING_HOURS.MODAL.BODY.CURRENTLY_BLOCKED' })} />

						<div className="row">
							<div>
								{currentBlocked.map((blockedItem) => (
									<BlockingItem
										key={blockedItem.id}
										id={blockedItem.id}
										date={blockedItem.date}
										startTime={blockedItem.startTime}
										endTime={blockedItem.endTime}
										tabol={blockedItem.tabol}
										inStore={blockedItem.inStore}
										widget={blockedItem.widget}
										edited={blockedItem.id === editedItem}
										onEdit={handleEdit}
										onSave={handleSave}
										onDelete={handleDelete}
									/>
								))}
							</div>
						</div>

						<Divider />
					</>
				)}

				<Label
					title={
						editedItem
							? intl.formatMessage({ id: 'BLOCKING_HOURS.MODAL.BODY.EDIT"' })
							: intl.formatMessage({ id: 'BLOCKING_HOURS.MODAL.BODY.BLOCK' })
					}
				/>

				<div className="row pb-5">
					<div className="col-12 col-sm-6">
						<SelectControl
							control={control}
							name="startTime"
							title={intl.formatMessage({ id: 'BLOCKING_HOURS.MODAL.BODY.START_BLOCK' })}
							options={blockingHours[formatDate(selectedDate)] ?? []}
							errors={errors}
							required
						/>
					</div>
					<div className="col-12 col-sm-6">
						<SelectControl
							control={control}
							name="endTime"
							title={intl.formatMessage({ id: 'BLOCKING_HOURS.MODAL.BODY.END_BLOCK' })}
							isDisabled={!selectedStartTime}
							options={(blockingHours[formatDate(selectedDate)] ?? []).filter((option) => option.value > selectedStartTime)}
							errors={errors}
							required
							responsiveTitle={true}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-12 d-flex flex-row">
						<CheckBox
							register={register}
							name="tabol"
							title={intl.formatMessage({ id: 'BLOCKING_HOURS.MODAL.BODY.TABOL' })}
							labelFirst={false}
							isSwitch={false}
						/>
						<CheckBox
							register={register}
							name="inStore"
							title={intl.formatMessage({ id: 'BLOCKING_HOURS.MODAL.BODY.IN_STORE' })}
							labelFirst={false}
							isSwitch={false}
						/>
						<CheckBox
							register={register}
							name="widget"
							title={intl.formatMessage({ id: 'BLOCKING_HOURS.MODAL.BODY.WIDGET' })}
							labelFirst={false}
							isSwitch={false}
						/>
					</div>
					{areaError && (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">
								<span role="alert">
									<FormattedMessage id="BLOCKING_HOURS.MODAL.BODY.ALERT" />
								</span>
							</div>
						</div>
					)}
				</div>
			</form>

			{devMode && <DevTool control={control} />}
		</div>
	);
};

export default BlockingModalBody;
