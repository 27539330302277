import { Control, Controller } from 'react-hook-form';

type Props = {
	control: Control<any>;
	title?: string;
	name: string;
	labelOff: string;
	labelOn: string;
	defaultValue?: boolean;
};

const Switch: React.FC<Props> = ({ control, title, name, labelOff, labelOn, defaultValue }) => {
	return (
		<Controller
			control={control}
			name={name}
			defaultValue={defaultValue}
			render={({ field: { onChange, onBlur, value, ref } }) => (
				<div className="col-12">
					<div className="py-3 fs-7 fw-bold">{title}</div>
					<div className="d-flex">
						<div className="form-check form-check-custom form-check-solid">
							<input
								className="form-check-input"
								type="radio"
								onBlur={onBlur}
								ref={ref}
								onChange={() => onChange(false)}
								checked={value === false}
								id="off"
							/>
							<label className="ms-2 fw-bold" htmlFor="off">
								{labelOff}
							</label>
						</div>
						<div className="form-check form-check-custom form-check-solid ps-5">
							<input
								className="form-check-input"
								type="radio"
								onBlur={onBlur}
								ref={ref}
								onChange={() => onChange(true)}
								checked={value === true}
								id="on"
							/>
							<label className="ms-2 fw-bold" htmlFor="on">
								{labelOn}
							</label>
						</div>
					</div>
				</div>
			)}
		/>
	);
};

export default Switch;
