import { useGetVisibleAppsQuery } from 'src/app/services/apiSlice';

const useCheckPermissions = (path, action) => {
	const { data, isLoading } = useGetVisibleAppsQuery({});

	const hasAccess = data?.[path]?.[action] ?? false;

	return { hasAccess, isLoading };
};

const useCheckReadPermissions = (path) => {
	return useCheckPermissions(path, 'r');
};

export { useCheckReadPermissions };
