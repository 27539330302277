import { useContext, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import TableContext from './context';

const TableSearch = () => {
	const { search, setSearch } = useContext(TableContext);

	const inputRef = useRef();
	const intl = useIntl();

	useEffect(() => {
		inputRef.current.value = search || '';
	}, [search]);

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			setSearch(event.target.value);
		}
	};

	const resetSearch = () => {
		inputRef.current.value = '';
		setSearch();
	};

	return (
		<div className="input-group input-search">
			<i className="fa-solid fa-magnifying-glass search-icon" />
			<input
				type="text"
				className="form-control p-2"
				data-role="tagsinput"
				placeholder={intl.formatMessage({ id: 'RESERVATIONS.LIST.TABLE_VALUES.SEARCH' })}
				ref={inputRef}
				onKeyDown={handleKeyPress}
			/>

			{search && (
				<button onClick={resetSearch}>
					<i className="bi bi-x-circle"></i>
				</button>
			)}
		</div>
	);
};

export default TableSearch;
