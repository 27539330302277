import jwtDecode from 'jwt-decode';
import { Navigate, useLocation } from 'react-router-dom';

import { isEmptyObj } from '../../common/misc';
import { getSessionStorage, removeLocalStorage, removeSessionStorage } from '../../common/storage';

function Private({ children }) {
	const location = useLocation();

	const token = getSessionStorage('accessToken');
	const jwt = token ? jwtDecode(token) : {};

	if (isEmptyObj(jwt)) {
		removeLocalStorage('refreshToken');
		removeSessionStorage('accessToken');
		removeSessionStorage('user');

		return <Navigate to="/auth/login" state={{ from: location }} replace />;
	}

	return children;
}

export default Private;
