const Divider = () => {
	return (
		<div className="row">
			<div className="col-12">
				<div className="separator my-5" />
			</div>
		</div>
	);
};

export default Divider;
