import React, { memo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ErrorsPage } from '../../components/errors/ErrorsPage';
import { AuthPage } from '../../pages/auth';
import App from '../App';
import Private from './Private';
import PrivateRoutes from './PrivateRoutes';

const AppRoutes = () => {
	return (
		<Routes>
			<Route element={<App />}>
				<Route path="auth/*" element={<AuthPage />} />
				<Route path="error/*" element={<ErrorsPage />} />

				<Route
					path="*"
					element={
						<Private>
							<Routes>
								<Route path="/*" element={<PrivateRoutes />} />
								<Route index element={<Navigate to="/dashboard" />} />
							</Routes>
						</Private>
					}
				/>
			</Route>
		</Routes>
	);
};

export default memo(AppRoutes);
