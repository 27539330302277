import { useEffect } from 'react';

import ToolbarActions from './ToolbarActions';

const Toolbar = () => {
	useEffect(() => {
		document.body.setAttribute('data-kt-app-toolbar-enabled', 'true');
	});

	return (
		<div className="d-flex align-items-center gap-2 gap-lg-3">
			<ToolbarActions />
		</div>
	);
};

export { Toolbar };
