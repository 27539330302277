const isExpiredJWT = (jwt) => jwt.exp < Date.now() / 1000;

const getLocalStorage = (key) => localStorage[key] || null;

const getSessionStorage = (key) => sessionStorage[key] || null;

const removeLocalStorage = (key) => localStorage.removeItem(key);

const removeSessionStorage = (key) => sessionStorage.removeItem(key);

const setLocalStorage = (key, value) => localStorage.setItem(key, value);

const setSessionStorage = (key, value) => sessionStorage.setItem(key, value);

export { getLocalStorage, getSessionStorage, isExpiredJWT, removeLocalStorage, removeSessionStorage, setLocalStorage, setSessionStorage };
