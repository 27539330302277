import { Modal } from 'react-bootstrap';

import { KTSVG } from '../../../_metronic/helpers';

const defaultContent = {
	title: 'Confirm',
	message: 'Are you sure about this action?',
	button: 'Proceed',
};

const ConfirmationModal = ({ show, onConfirm, content = defaultContent }) => {
	return (
		<Modal
			id="kt_modal_action_confirmation"
			tabIndex={-1}
			aria-hidden="true"
			dialogClassName="modal-dialog modal-dialog-centered mw-500px"
			show={show}
			onHide={() => onConfirm(false)}
			backdrop
		>
			<div className="modal-header">
				<h2>{content.title}</h2>
				<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => onConfirm(false)}>
					<KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
				</div>
			</div>

			<div className="modal-body py-lg-10 px-lg-10">
				<p className="fs-4 fw-bold text-gray-700">{content.message}</p>
			</div>

			<div className="modal-footer">
				<button type="button" className="btn btn-secondary" onClick={() => onConfirm(false)}>
					Cancel
				</button>
				<button type="button" className="btn btn-primary" onClick={() => onConfirm(true)}>
					{content.button}
				</button>
			</div>
		</Modal>
	);
};

export default ConfirmationModal;
