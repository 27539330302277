import clsx from 'clsx';
import { FieldValues, UseFormRegister } from 'react-hook-form';

type Button = {
	value: string;
	label: string;
};

type Props = {
	register: UseFormRegister<FieldValues>;
	title?: string;
	name: string;
	buttons: Array<Button>;
	disabled?: boolean;
};

const Radio: React.FC<Props> = ({ register, title, name, buttons, disabled = false }) => {
	return (
		<div className="col-12">
			<div className="pb-3 fs-7 fw-bold pt-5">{title}</div>
			<div className="d-flex">
				{buttons.map(({ value, label }, idx) => (
					<div key={value} className={clsx('form-check form-check-custom form-check-solid', { 'ps-5': idx > 0 })}>
						<input className="form-check-input" {...register(name)} type="radio" value={value} id={value} disabled={disabled} />
						<label className="ms-2 fw-bold" htmlFor={value}>
							{label}
						</label>
					</div>
				))}
			</div>
		</div>
	);
};

export default Radio;
