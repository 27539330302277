import clsx from 'clsx';
import { toAbsoluteUrl } from 'src/_metronic/helpers';
import { BUSINESS_LANGUAGES as LOCALES } from 'src/common/constants';

type LocalesProps = {
	lang: string;
	setLang: (lang: string) => void;
};

const Locales = ({ lang, setLang }: LocalesProps) => {
	return (
		<>
			{LOCALES.map((bl) => (
				<span
					key={bl.code}
					id={bl.code}
					onClick={(e) => setLang(e.currentTarget.id)}
					className={clsx('p-1 pe-2 flag-image-container', { active: lang === bl.code })}>
					<img className="flag-image rounded-circle" src={toAbsoluteUrl(bl.flag)} alt={bl.name} />
					<span className="fs-7">{bl.abbr}</span>
				</span>
			))}
		</>
	);
};

export default Locales;
