import { createContext, useContext } from 'react';

export const ModalContext = createContext();

const defaultMaxWidth = 'mw-800px';

const useModal = () => {
	const context = useContext(ModalContext);

	if (!context?.maxWidth) {
		context.maxWidth = defaultMaxWidth;
	}

	return context;
};

export default useModal;
