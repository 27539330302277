import clsx from 'clsx';
import { Children, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ConfirmationModal from 'src/_metronic/partials/modals/ConfirmationModal';

import { KTSVG } from '../../_metronic/helpers';
import TableContext from './context';
import styles from './TableBody.scss';

var lastChildField = null;

const TableBody = ({ children }) => {
	const { items, isLoading, isSuccess } = useContext(TableContext);

	const childrenArray = Children.toArray(children);
	lastChildField = childrenArray[childrenArray.length - 1]?.props?.field;
	let body;
	if (isLoading) {
		body = (
			<TableContentAlert>
				{/* <FormattedMessage id="TABLE.BODY.LOADING" /> */}
				<img src="/media/logos/Loader-table.svg" alt="Tabol logo" />
			</TableContentAlert>
		);
	} else if (!isLoading && !isSuccess) {
		body = (
			<TableContentAlert>
				<div className="d-flex justify-content-center align-items-center py-5">
					<img src="/media/logos/Tabol-Logo.png" alt="Tabol Logo" className="img-fluid max-height-200" />
				</div>
				<div className="text-muted fw-bold">
					<FormattedMessage id="TABLE.BODY.ERROR" />
				</div>
			</TableContentAlert>
		);
	} else if (!items || items.length === 0) {
		body = (
			<TableContentAlert>
				<div className="d-flex justify-content-center flex-column align-items-center py-5">
					<img src="/media/logos/Tabol-Logo.png" alt="Tabol Logo" className="img-fluid max-height-200" />
				</div>
				<div className="text-muted fw-bold">
					<FormattedMessage id="TABLE.BODY.NODATA" />
				</div>
			</TableContentAlert>
		);
	} else {
		body = items.map((item) => (
			<TableRow key={item._id}>
				<TableRowCheck id={item._id} />
				{children.map(({ props: { type, field, formatValue, body, capitalize, hidden, truncate } }) => (
					<TableCell
						key={field}
						hidden={hidden}
						type={type}
						field={field}
						formatValue={formatValue}
						body={body}
						capitalize={capitalize}
						truncate={truncate}
					>
						{item}
					</TableCell>
				))}
			</TableRow>
		));
	}

	return <tbody>{body}</tbody>;
};

const TableContentAlert = ({ children }) => {
	return (
		<tr>
			<td colSpan="12" style={{ textAlign: 'center' }}>
				{children}
			</td>
		</tr>
	);
};

const TableRow = ({ children }) => {
	return <tr>{children}</tr>;
};

const TableRowCheck = ({ id }) => {
	const { selectable, selectedRows, selectRows } = useContext(TableContext);
	if (!selectable) return;

	return (
		<td>
			<div className="form-check form-check-sm form-check-custom form-check-solid">
				<input
					className="form-check-input widget-13-check"
					type="checkbox"
					name={id}
					checked={selectedRows.includes(id)}
					onChange={() => selectRows([id])}
				/>
			</div>
		</td>
	);
};

const TableCell = ({ children, type, field, formatValue, body, capitalize, hidden, truncate }) => {
	if (hidden) return;
	let values = getValuesByDotNotation(children, field);
	let value = '';
	if (typeof formatValue === 'function') {
		value = formatValue(...values);
	} else if (typeof body === 'function') {
		value = body(children);
	} else {
		value = values.map((v) => v);
	}

	let cell;
	switch (type) {
		case 'href':
			cell = children && (
				<a className={clsx('text-dark fw-bold text-hover-primary fs-6', { 'text-capitalize': capitalize })} href={value}>
					{value}
				</a>
			);
			break;
		case 'custom':
			cell = value;
			break;
		case 'span':
		default:
			cell = (
				<span className={clsx('text-dark fw-bold text-hover-primary fs-6', { 'text-capitalize': capitalize }, { truncateText: truncate })}>
					{value ?? ''}
				</span>
			);
	}

	return <td className={clsx({ 'text-end': lastChildField === field })}>{cell}</td>;
};

const TableCellActions = ({ children }) => {
	return <td className="text-end">{children}</td>;
};

const TableCellActionItems = ({ icon, action, confirmAction }) => {
	const [show, setShow] = useState(false);

	const handleActionConfirmation = (confirmed) => {
		setShow(false);

		confirmed && action();
	};

	const handleOnClick = () => {
		if (confirmAction) {
			setShow(true);
		} else {
			action();
		}
	};

	return (
		<>
			{show && <ConfirmationModal onConfirm={handleActionConfirmation} />}
			<span className="btn btn-icon btn-bg-light btn-sm me-1 btn-color-primary" onClick={handleOnClick}>
				<KTSVG path={icon} className="svg-icon-3" />
			</span>
		</>
	);
};

function getValuesByDotNotation(object, dotNotation = '') {
	// Split the dot notation into an array of field notations
	const fields = dotNotation.split(',');
	let values = [];

	for (const field of fields) {
		// Split the field dot notation into an array of keys
		const keys = field.split('.');

		// Initialize a variable to store the current value
		let value = object;

		// Iterate through the keys and check for undefined at each level
		for (const key of keys) {
			if (value && typeof value === 'object' && key in value) {
				// If the value is not undefined and the key exists in the current object,
				// update the value for the next iteration
				value = value[key];
			} else {
				// If the value is undefined or the key doesn't exist, set value to undefined and break
				value = undefined;
				break;
			}
		}
		values.push(value);
	}

	return values;
}

export { TableBody, TableCell, TableCellActionItems, TableCellActions, TableRow, TableRowCheck };
