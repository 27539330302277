type LabelProps = {
	title: string;
};

const Label = ({ title }: LabelProps) => {
	return (
		<div className="row pb-5">
			<div className="col-12">
				<div className="mb-0 fs-2 fw-bold">{title}</div>
			</div>
		</div>
	);
};

export default Label;
