import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import FilterBody from './FilterBody';
import FilterHeader from './FilterHeader';

const Filters = ({ filters, onAply }) => {
	return (
		<>
			<FilterHeader>
				<div className="row">
					<div className="col-12">
						<div className="fw-bold fs-6 py-5"><FormattedMessage id="FILTER.OPTIONS" /></div>
					</div>
				</div>
			</FilterHeader>
			<div className="separator" />
			<FilterBody filters={filters} onAply={onAply} />
		</>
	);
};

export default Filters;
