import 'react-datepicker/dist/react-datepicker.css';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { KTSVG } from 'src/_metronic/helpers';
import { customStyles } from 'src/common/inputStyles';
import { Datepicker } from 'src/components/input';
import useTableWithItems from 'src/components/table/useTableWithItems';

import { useStoreQuery } from '../../../reducers/business/businessApiSlice';
import { useSearchContactsQuery } from '../../../reducers/contactApiSlice';
import { useGetStoreAvailabilityQuery } from '../../../reducers/reservationApiSlice';
import AddGuests from './AddGuests';
import GuestsTable from './GuestsTable';
import MergedTableDropdown from './MergedTableDropdown';
import ReservationTable from './ReservationTable';
import { durationOptions, headers, partySizeOptions, phoneNumberOptions, sourceOptions, statusOptions } from './ResOptions';

const ReservationDetailsOptions = (props) => {
	const {
		control,
		register,
		errors,
		onSelectPreferenceChange,
		selectedTablesId,
		setSelectedTablesId,
		setNewGuest,
		stores,
		onStoreIdChange,
		storeId,
		selectedServiceAreaId,
		onAreaIdChange,
		startDate,
		setStartDate,
		formattedDate,
		availableTime,
		setAvailableTime,
		setValue,
		setGuestType,
		setGuestId,
		businessId,
		isReservationSuccess,
		reservation,
	} = props;

	const [editingGuest, setEditingGuest] = useState(null);
	const [peopleNumber, setPeopleNumber] = useState(partySizeOptions[0].value);
	const [people, setPeople] = useState(partySizeOptions[0].id);
	const [guestList, setGuestList] = useState([]);
	const [openDropdown, setOpenDropdown] = useState('');
	const [search, setSearch] = useState('');
	const [areas, setAreas] = useState([]);
	const [tables, setTables] = useState([]);
	const [disable, setDisable] = useState(false);

	const animatedComponents = makeAnimated();
	const dropdownValue = openDropdown;

	const handleSearchChange = (event) => {
		setSearch(event.target.value);
	};

	const toggleDropdown = (name) => {
		setOpenDropdown(openDropdown === name ? '' : name);
	};

	const handleOpenDropdownChange = (value) => {
		setOpenDropdown(value);
	};

	const handleEditGuest = (id) => {
		const guestToEdit = guestList.find((guest) => guest.id === id);
		setEditingGuest(guestToEdit);
	};

	const handleDeleteGuest = (id) => {
		setGuestList((prevGuestList) => {
			return prevGuestList.filter((guest) => guest.id !== id);
		});
	};

	useEffect(() => {
		setNewGuest(guestList);
	}, [guestList]);

	const handleAddGuest = (guest, isUpdating) => {
		if (isUpdating) {
			setGuestList((prevGuestList) => prevGuestList.map((g) => (g.id === guest.id ? guest : g)));
			setNewGuest((prevGuestList) => prevGuestList.map((g) => (g.id === guest.id ? guest : g)));
		} else {
			setGuestList((prevGuestList) => [...prevGuestList, guest]);
			setNewGuest((prevGuestList) => [...prevGuestList, guest]);
		}
	};

	let content = '';
	const tableValues = useMemo(() => {
		return {
			title: '',
			items: guestList,
			totalRows: Object.keys(guestList).length,
			content: <GuestsTable onEditGuest={handleEditGuest} onDeleteGuest={handleDeleteGuest} />,
		};
	}, [guestList]);

	const { table } = useTableWithItems(tableValues);
	if (Object.keys(guestList).length > 0) {
		content = table;
	}

	const handleStoreIdChange = (newStoreId) => {
		onStoreIdChange(newStoreId);
	};

	const handleServiceAreaIdChange = (selectedOption) => {
		onAreaIdChange(selectedOption.id);
	};

	function arraysAreEqual(arr1, arr2) {
		return arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);
	}

	const handleTableSelection = (selectedTables) => {
		const selectedTableLabels = selectedTables.map((table) => table.type);

		const selectedTableIds = tableOptions.filter((table) => selectedTableLabels.includes(table.label)).map((table) => table.id);

		if (!arraysAreEqual(selectedTablesId, selectedTableIds)) {
			setSelectedTablesId(selectedTableIds);
		}
	};

	// const handlePreferencesChange = (selectedOption) => {
	//     onSelectPreferenceChange(selectedOption);
	// };

	const firstRef = useRef();
	const secondRef = useRef();
	const thirdRef = useRef();
	const storeRef = useRef();
	const timeRef = useRef();
	const partySizeRef = useRef();
	const sourceRef = useRef();
	const statusRef = useRef();
	const durationRef = useRef();
	const phoneRef = useRef();
	const tableRef = useRef();
	const hiddenPhoneRef = useRef();

	const dropdownRefs = [
		firstRef,
		secondRef,
		thirdRef,
		storeRef,
		timeRef,
		partySizeRef,
		sourceRef,
		statusRef,
		durationRef,
		phoneRef,
		tableRef,
		hiddenPhoneRef,
	];

	useEffect(() => {
		const handleClick = (ev) => {
			const isClickInsideAnyDropdown = dropdownRefs.some((ref) => ref.current && ref.current.contains(ev.target));
			const isClickInsideOpenDropdown = openDropdown !== '';

			if (!isClickInsideAnyDropdown && isClickInsideOpenDropdown) {
				setOpenDropdown('');
			}
		};

		window.addEventListener('click', handleClick);
		return () => {
			window.removeEventListener('click', handleClick);
		};
	}, [dropdownRefs, openDropdown]);

	// Fetch to get the preferences of the store

	const [preferencesOptions, setPreferencesOptions] = useState({});
	const [lastValidSelection, setLastValidSelection] = useState([]);
	const args3 = storeId !== '' ? { path: { bid: businessId, sid: storeId } } : skipToken;
	const { data: preferences = [], isSuccess: isPreferencesSuccess } = useStoreQuery(args3);

	useEffect(() => {
		if (preferences.length === 0) {
			return;
		}
		const transformedOptions = preferences.map((pref) => ({
			label: pref.label,
			value: pref.value,
		}));
		setPreferencesOptions(transformedOptions);
	}, [preferences]);

	const handlePreferencesChange = (selectedOptions) => {
		let hasIncompatibility = false;

		for (let i = 0; i < selectedOptions.length; i++) {
			const selectedOption = selectedOptions[i];
			// Find the full preference by comparing the 'value' of selectedOption with 'value' of preferences
			const fullPreference = preferences.find((pref) => pref.value === selectedOption.value);

			if (fullPreference) {
				for (let j = 0; j < selectedOptions.length; j++) {
					if (i !== j && fullPreference.incompatible.includes(selectedOptions[j].value)) {
						hasIncompatibility = true;
						break;
					}
				}
			}
			if (hasIncompatibility) {
				break;
			}
		}

		if (hasIncompatibility) {
			alert('Some of the selected options are incompatible with each other.');
			onSelectPreferenceChange(lastValidSelection);
		} else {
			setLastValidSelection(selectedOptions);
			onSelectPreferenceChange(selectedOptions);
		}
	};

	const today = new Date();
	today.setHours(0, 0, 0, 0);
	const startOfDay = new Date(startDate);
	startOfDay.setHours(0, 0, 0, 0);

	// Fetch to get the available hours based on the date
	const args = storeId !== '' && startOfDay >= today ? { path: { id: storeId }, query: { date: formattedDate, people: peopleNumber } } : skipToken;
	const { data: availabiltyResponse = {}, isSuccess: isAvailabilitySuccess } = useGetStoreAvailabilityQuery(args);

	const [availableHours, setAvailableHours] = useState([]);
	const [defaultAvailableHour, setDefaultAvailableHour] = useState('');

	useEffect(() => {
		if (isAvailabilitySuccess) {
			const availability = availabiltyResponse.availability || [];
			if (availability.length === 0) {
				setAvailableTime('');
				setAvailableHours([]);
				setAreas([]);
				setTables([]);
			} else {
				const currentDate = new Date();
				const currentHour = currentDate.getHours();
				const currentMinute = currentDate.getMinutes();

				let filteredAvailability = availability;
				if (
					startDate.getDate() === currentDate.getDate() &&
					startDate.getMonth() === currentDate.getMonth() &&
					startDate.getFullYear() === currentDate.getFullYear()
				) {
					filteredAvailability = availability.filter((slot) => {
						const [slotHour, slotMinute] = slot.time.split(':').map(Number);
						return slotHour > currentHour || (slotHour === currentHour && slotMinute >= currentMinute);
					});
				}

				const hours = filteredAvailability.map((slot) => ({
					value: slot.time,
					label: slot.time + (slot.discount ? ` - ${slot.discount}` : ''),
				}));
				setAvailableHours(hours);
				if (hours.length) {
					setDefaultAvailableHour(hours[0].label);
				}
			}
		}
	}, [isAvailabilitySuccess, availabiltyResponse.availability, startDate]);

	const defaultHour = useMemo(() => {
		return availableHours.length > 0 ? defaultAvailableHour : '';
	}, [availableHours, defaultAvailableHour]);

	useEffect(() => {
		setAvailableTime(defaultHour);
		setValue('selectedTimeOption', defaultHour);
	}, [defaultHour, setAvailableTime, setValue]);

	useEffect(() => {
		const selectedTimeData = availabiltyResponse.availability?.find((slot) => slot.time === availableTime);
		const areaOptions = selectedTimeData
			? selectedTimeData.areas.map((area) => ({
					id: area['_id'],
					value: area.title,
					label: area.title,
			  }))
			: [];
		setAreas(areaOptions);
	}, [availableTime, availabiltyResponse.availability]);

	const [tableOptions, setTableOptions] = useState([]);

	useEffect(() => {
		const selectedTimeData = availabiltyResponse.availability?.find((slot) => slot.time === availableTime);
		const selectedAreaData = selectedTimeData ? selectedTimeData.areas.find((area) => area['_id'] === selectedServiceAreaId) : undefined;

		const newTables = selectedAreaData
			? selectedAreaData.tables.map((table) => ({
					id: table['_id'],
					value: table.title,
					label: table.title,
			  }))
			: [];
		setTables(newTables.map((table) => table.label));
		setTableOptions(newTables);
	}, [availableTime, availabiltyResponse.availability, selectedServiceAreaId]);

	// Fetch to get users
	const args2 = search.length >= 3 ? { query: { search: search } } : skipToken;
	const { data: availableContacts = [], isSuccess: isContactSuccess } = useSearchContactsQuery(args2);

	const handleContactClick = (contact) => {
		setValue('firstname', contact.fname);
		setValue('lastname', contact.lname);
		setValue('email', contact.email);
		const prefix = contact.phone.slice(0, 3);
		const phone = contact.phone.slice(-10);
		setValue('selectedPhoneNumberOption', prefix);
		setValue('phoneNumber', phone);
		setGuestType('contact');
		setGuestId(contact.id);
		setSearch('');
		setDisable(true);
	};

	const handleUserClick = (user) => {
		setValue('firstname', user.fname);
		setValue('lastname', user.lname);
		setValue('email', user.email);
		const prefix = user.mobile.slice(0, 3);
		const phone = user.mobile.slice(-10);
		setValue('selectedPhoneNumberOption', prefix);
		setValue('phoneNumber', phone);
		setGuestType('tabol_user');
		setGuestId(user.id);
		setSearch('');
		setDisable(true);
	};

	const handleResetGuestsFields = () => {
		setValue('firstname', '');
		setValue('lastname', '');
		setValue('email', '');
		setValue('phoneNumber', '');
		setGuestType('new_contact');
		setGuestId('');
		setDisable(false);
	};

	// Edit Mode

	const findMatchingServiceAreas = useCallback(
		(reservationArea) => {
			return areas.find((option) => option.id === reservationArea);
		},
		[areas]
	);

	const findMatchingPreferences = useCallback(
		(reservationPreferences) => {
			return preferences.filter((preferenceOption) => reservationPreferences.some((resPref) => resPref._id === preferenceOption.value));
		},
		[preferences]
	);

	const transformGuestUsers = useCallback((guestUsers) => {
		return guestUsers.map((guest) => {
			const phone = guest.phone.replace(/[^\d]/g, '');
			const prefix = phone.slice(0, 2);
			const phoneNumber = phone.slice(-10);

			return {
				email: guest.email || '',
				firstname: guest.fname,
				lastname: guest.lname,
				id: guest._id,
				phoneNumber: phoneNumber,
				selectedGuestPhoneNumberOption: `+${prefix}`,
				type: guest._id ? 'contact' : 'tabol_user',
			};
		});
	}, []);

	const [editModeChildFlag, setEditModeChildFlag] = useState(true);
	const [editTime, setEditTime] = useState(null);
	const [editPeopleSize, setEditPeopleSize] = useState(null);
	const [editSource, setEditSource] = useState(null);
	const [editStatus, setEditStatus] = useState(null);
	const [editDuration, setEditDuration] = useState(null);
	const [editPreferences, setEditPreferences] = useState(null);
	const [editServiceAreas, setEditServiceAreas] = useState(null);
	const [editTables, setEditTables] = useState([]);

	useEffect(() => {
		if (isReservationSuccess && reservation && availableHours.length > 0 && editModeChildFlag && isPreferencesSuccess) {
			const timePart = reservation.reservation.date.split('T')[1].substring(0, 5);
			const matchingTimeOption = availableHours.find((option) => option.value === timePart);
			setEditTime(matchingTimeOption);

			const matchingPeopleOption = partySizeOptions.find((option) => option.value === reservation.reservation.people.toString());
			setEditPeopleSize(matchingPeopleOption);
			setPeople(matchingPeopleOption);

			const matchingSourceOption = sourceOptions.find((option) => option.value === reservation.reservation.source);
			setEditSource(matchingSourceOption);

			const matchingStatusOption = statusOptions.find((option) => option.value === reservation.reservation.status);
			setEditStatus(matchingStatusOption);

			const matchingDurationOption = durationOptions.find((option) => option.value === reservation.reservation.duration);
			setEditDuration(matchingDurationOption);

			const matchingPreferenceOptions = findMatchingPreferences(reservation.reservation.preferences);
			setEditPreferences(matchingPreferenceOptions);

			setValue('firstname', reservation.reservation.host.fname);
			setValue('lastname', reservation.reservation.host.lname);
			setValue('email', reservation.reservation.host.email);
			if (reservation.reservation.host.phone) {
				const prefix = reservation.reservation.host.phone.slice(0, 3);
				const phone = reservation.reservation.host.phone.slice(-10);
				setValue('selectedPhoneNumberOption', prefix);
				setValue('phoneNumber', phone);
			}

			if (reservation.reservation.host._id) {
				setGuestType('contact');
				setGuestId(reservation.reservation.host._id);
			} else {
				setGuestType('tabol_user');
				setGuestId(reservation.reservation.host.tabol_user_id);
			}

			if (reservation.reservation.guest_users.length > 0) {
				const transformedGuestUsers = transformGuestUsers(reservation.reservation.guest_users);
				setGuestList(transformedGuestUsers);
				setNewGuest(transformedGuestUsers);
			}
		}
	}, [isReservationSuccess, reservation, availableHours, editModeChildFlag, isPreferencesSuccess]);

	// Separate useEffect for handling areas
	useEffect(() => {
		if (areas.length > 0 && reservation) {
			const matchingServiceAreaOption = findMatchingServiceAreas(reservation.reservation.area._id);
			setEditServiceAreas(matchingServiceAreaOption);
		}
	}, [areas, reservation]);

	useEffect(() => {
		if (editTime != null && editModeChildFlag) {
			const selectedTimeData = availabiltyResponse.availability?.find((slot) => slot.time === editTime.value);
			const selectedAreaData = selectedTimeData
				? selectedTimeData.areas.find((area) => area['_id'] === reservation.reservation.area._id)
				: undefined;
			const newTables = selectedAreaData
				? selectedAreaData.tables.map((table) => ({
						id: table['_id'],
						value: table.title,
						label: table.title,
				  }))
				: [];
			const matchedTableNames = reservation.reservation.tables
				.map((resTable) => {
					const match = newTables.find((newTable) => newTable.id === resTable._id);
					return match ? match.value : null;
				})
				.filter((value) => value !== null);
			setEditTables(matchedTableNames);
			const matchedIds = reservation.reservation.tables
				.map((resTable) => {
					const match = newTables.find((newTable) => newTable.id === resTable._id);
					return match ? match.id : null;
				})
				.filter((id) => id !== null);
			setSelectedTablesId(matchedIds);
			setEditModeChildFlag(false);
		}
	}, [reservation, editTime]);

	useEffect(() => {
		if (editTime) {
			setValue('selectedTimeOption', editTime.label);
			setAvailableTime(editTime.value);
			setEditTime(null);
		}
		if (editPeopleSize) {
			setValue('selectedPartySizeOption', editPeopleSize.value);
			setEditPeopleSize(null);
		}
		if (editSource) {
			setValue('selectedSourceOption', editSource.label);
			setEditSource(null);
		}
		if (editStatus) {
			setValue('selectedStatusOption', editStatus.label);
			setEditStatus(null);
		}
		if (editDuration) {
			setValue('selectedDurationOption', editDuration.label);
			setEditDuration(null);
		}
		if (editPreferences) {
			setValue('selectedPreferencesOptions', editPreferences);
			handlePreferencesChange(editPreferences);
			setEditPreferences(null);
		}
		if (editServiceAreas) {
			setValue('serviceArea', editServiceAreas);
			handleServiceAreaIdChange(editServiceAreas);
			setEditServiceAreas(null);
		}
	}, [editTime, editPeopleSize, editSource, editStatus, editDuration, editPreferences, editServiceAreas]);

	return (
		<>
			<div className="row pb-5">
				{stores.length > 0 ? (
					<Controller
						name="selectedStoreOption"
						control={control}
						defaultValue={stores[0].label}
						render={({ field }) => (
							<div className="col-12 col-sm-6">
								<div className="pb-3 fs-7 fw-bold">
									<FormattedMessage id="RESERVATIONS.DETAILS.MODAL_BODY.LOCATION" />
								</div>
								<div className={clsx('custom-select', { open: openDropdown === 'store' })}>
									{/* <div className="custom-select-icon icon-left">
										<i className="fa-solid fa-calendar" />
									</div> */}
									<div className="select-wrapper">
										<div className="select-header icon-left p-4" onClick={() => toggleDropdown('store')} ref={storeRef}>
											<span className="selected-option fs-6 fw-bold">{field.value}</span>
											<i className="fa-solid fa-chevron-down arrow-icon" />
										</div>
										<ul className={clsx('select-options fs-6 fw-bold', { 'd-none': openDropdown !== 'store' })}>
											{stores.map((storeOption) => (
												<li
													key={storeOption.value}
													className={clsx('option', { active: field.value === storeOption.label })}
													onClick={() => {
														field.onChange(storeOption.label);
														// handleOptionSelect(storeOption.label)
														handleStoreIdChange(storeOption.id);
														setOpenDropdown('');
													}}
												>
													{storeOption.label}
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>
						)}
					/>
				) : (
					<div>
						<FormattedMessage id="RESERVATION.DETAILS_OPTIONS.LOADING_STORES" />
					</div>
				)}

				<div className="col-12 col-sm-6">
					{/* <div className="pb-3 pt-5 pt-sm-0 fs-7 fw-bold">
						<FormattedMessage id="RESERVATIONS.DETAILS.MODAL_BODY.DATE" />
					</div> */}
					<Datepicker
						control={control}
						name="date"
						title="Date"
						startDate={startDate}
						selected={startDate}
						onChange={(newDate) => setStartDate(newDate)}
						responsiveTitle={true}
					/>
					{/* <div className="input-group">
						<div className="input-group-prepend">
							<span className="input-group-text">
								<i className="fas fa-calendar-alt" />
								<DatePicker
									selected={startDate}
									onChange={(newDate) => setStartDate(newDate)}
									className="form-control custom-date-picker"
								/>
							</span>
						</div>
					</div> */}
				</div>
			</div>

			<div className="row pb-5">
				<Controller
					name="selectedTimeOption"
					control={control}
					render={({ field }) => (
						<div className="col-12 col-sm-6">
							<div className="pb-3 fs-7 fw-bold">
								<FormattedMessage id="RESERVATION.DETAILS_OPTIONS.TIME" />
							</div>
							<div className={clsx('custom-select', { open: openDropdown === 'time' })}>
								<div className="custom-select-icon icon-left">
									<KTSVG path="/media/icons/duotune/general/gen013.svg" className="svg-icon-muted svg-icon-2x" />
								</div>
								<div className="select-wrapper">
									<div className="select-header icon-left p-4" onClick={() => toggleDropdown('time')} ref={timeRef}>
										<span className="selected-option fs-6 fw-bold">
											{availableHours.length > 0 && isAvailabilitySuccess ? field.value : 'No available Hours'}
										</span>
										{availableHours.length > 0 && isAvailabilitySuccess && <i className="fa-solid fa-chevron-down arrow-icon" />}
									</div>
									<ul
										className={clsx('select-options fs-6 fw-bold', {
											'd-none': openDropdown !== 'time' || availableHours.length === 0 || !isAvailabilitySuccess,
										})}
									>
										{availableHours.map((timeOption) => (
											<li
												key={timeOption.value}
												className={clsx('option', { active: field.value === timeOption.label })}
												onClick={() => {
													field.onChange(timeOption.label);
													setOpenDropdown('');
													setAvailableTime(timeOption.value);
												}}
											>
												{timeOption.label}
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
					)}
				/>

				<Controller
					name="selectedPartySizeOption"
					control={control}
					defaultValue={partySizeOptions[0].label}
					render={({ field }) => (
						<div className="col-12 col-sm-6">
							<div className="pb-3 pt-5 pt-sm-0 fs-7 fw-bold">
								<FormattedMessage id="RESERVATION.DETAILS_OPTIONS.PARTY_SIZE" />
							</div>
							<div className={clsx('custom-select', { open: openDropdown === 'partySize' })}>
								{/* <div className="custom-select-icon icon-left">
									<i className="fa-solid fa-calendar" />
								</div> */}
								<div className="select-wrapper">
									<div className="select-header icon-left p-4" onClick={() => toggleDropdown('partySize')} ref={partySizeRef}>
										<span className="selected-option fs-6 fw-bold">{field.value}</span>
										<i className="fa-solid fa-chevron-down arrow-icon" />
									</div>
									<ul className={clsx('select-options fs-6 fw-bold', { 'd-none': openDropdown !== 'partySize' })}>
										{partySizeOptions.map((partySizeOption) => (
											<li
												key={partySizeOption.value}
												className={clsx('option', { active: field.value === partySizeOption.label })}
												onClick={() => {
													field.onChange(partySizeOption.label);
													setOpenDropdown('');
													setPeople(partySizeOption.id);
													setPeopleNumber(partySizeOption.value);
												}}
											>
												{partySizeOption.label}
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
					)}
				/>
			</div>

			<div className="row pb-5">
				<div className="col-12">
					<div className="input-group">
						<label htmlFor="guest-email" className="pb-3 mb-0 fs-7 fw-bold">
							<FormattedMessage id="RESERVATION.DETAILS_OPTIONS.SERVICE_AREA" />
						</label>
						<Controller
							name="serviceArea"
							control={control}
							defaultValue={areas.length > 0 ? areas[0] : ''}
							render={({ field }) => (
								<Select
									{...field}
									// styles={{
									// 	control: (baseStyles) => ({
									// 		...baseStyles,
									// 		borderColor: 'transparent',
									// 		backgroundColor: '#f9f9f9',
									// 	}),
									// 	option: (baseStyles) => ({
									// 		...baseStyles,
									// 		backgroundColor: '#f9f9f9',
									// 		color: 'black',
									// 	}),
									// }}
									styles={customStyles}
									className="basic-single"
									classNamePrefix="select"
									// placeholder={areas.length > 0 ? areas[0].label : 'Select...'}
									options={areas}
									onChange={(selectedOption) => {
										field.onChange(selectedOption);
										handleServiceAreaIdChange(selectedOption);
									}}
								/>
							)}
						/>
					</div>
				</div>
			</div>

			{tables.length > 0 ? (
				<MergedTableDropdown
					openDropdownValue={dropdownValue}
					onOpenDropdownChange={handleOpenDropdownChange}
					tableRef={dropdownRefs}
					onTableSelection={handleTableSelection}
					tables={tables}
					editTables={editTables}
					setEditTables={setEditTables}
					isReservationSuccess={isReservationSuccess}
				/>
			) : (
				<div className="col-12 col-sm-6">
					<div className="pb-3 fs-7 fw-bold">
						<FormattedMessage id="RESERVATION.MERGED_TABLE.ASSIGNED_TABLE" />
					</div>
					<div className="custom-select">
						{/* <div className="custom-select-icon icon-left">
							<i className="fa-solid fa-calendar" />
						</div> */}
						<div className="select-wrapper">
							<div className="select-header location-field p-4">
								<span className="selected-option">
									<FormattedMessage id="RESERVATION.DETAILS_OPTIONS.NO_AVAILABLE_TABLES" />
								</span>
							</div>
						</div>
					</div>
				</div>
			)}

			<div className="row pt-5">
				<div className="col-12">
					<div className="input-group">
						<label htmlFor="guest-email" className="pb-3 mb-0 fs-7 fw-bold">
							<FormattedMessage id="RESERVATIONS.DETAILS.MODAL_BODY.PREFERENCES" />
						</label>
						<Controller
							name="selectedPreferencesOptions"
							control={control}
							render={({ field }) => (
								<Select
									{...field}
									// styles={{
									// 	control: (baseStyles) => ({
									// 		...baseStyles,
									// 		borderColor: 'transparent',
									// 		backgroundColor: '#f9f9f9',
									// 	}),
									// 	option: (baseStyles) => ({
									// 		...baseStyles,
									// 		backgroundColor: '#f9f9f9',
									// 	}),
									// }}
									styles={customStyles}
									onChange={(selected) => {
										field.onChange(selected);
										handlePreferencesChange(selected); // if additional handling is needed
									}}
									value={lastValidSelection}
									closeMenuOnSelect={false}
									components={animatedComponents}
									isMulti
									options={preferencesOptions}
								/>
							)}
						/>
					</div>
				</div>
			</div>

			<div className="row pt-5">
				<Controller
					name="selectedSourceOption"
					control={control}
					defaultValue={sourceOptions[0].value}
					render={({ field }) => (
						<div className="col-md-4">
							<div className="pb-3 fs-7 fw-bold">
								<FormattedMessage id="RESERVATIONS.DETAILS.MODAL_BODY.SOURCE" />
							</div>
							<div className={clsx('custom-select', { open: openDropdown === 'source' })}>
								{/* <div className="custom-select-icon icon-left">
									<i className="fa-solid fa-calendar" />
								</div> */}
								<div className="select-wrapper">
									<div className="select-header icon-left p-4" onClick={() => toggleDropdown('source')} ref={sourceRef}>
										<span className="selected-option fs-6 fw-bold">{field.value}</span>
										<i className="fa-solid fa-chevron-down arrow-icon" />
									</div>
									<ul className={clsx('select-options fs-6 fw-bold', { 'd-none': openDropdown !== 'source' })}>
										{sourceOptions.map((sourceOption) => (
											<li
												key={sourceOption.value}
												className={clsx('option', { active: field.value === sourceOption.label })}
												onClick={() => {
													field.onChange(sourceOption.value);
													setOpenDropdown('');
												}}
											>
												{sourceOption.value}
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
					)}
				/>

				<Controller
					name="selectedStatusOption"
					control={control}
					defaultValue={statusOptions[0].value}
					render={({ field }) => (
						<div className="col-md-4">
							<div className="pb-3 pt-5 pt-sm-0 fs-7 fw-bold">
								<FormattedMessage id="RESERVATIONS.DETAILS.MODAL_BODY.STATUS" />
							</div>
							<div className={clsx('custom-select', { open: openDropdown === 'status' })}>
								{/* <div className="custom-select-icon icon-left">
									<i className="fa-solid fa-calendar" />
								</div> */}
								<div className="select-wrapper">
									<div className="select-header icon-left p-4" onClick={() => toggleDropdown('status')} ref={statusRef}>
										<span className="selected-option fs-6 fw-bold">{field.value}</span>
										<i className="fa-solid fa-chevron-down arrow-icon" />
									</div>
									<ul className={clsx('select-options fs-6 fw-bold', { 'd-none': openDropdown !== 'status' })}>
										{statusOptions.map((statusOption) => (
											<li
												key={statusOption.value}
												className={clsx('option', { active: field.value === statusOption.label })}
												onClick={() => {
													field.onChange(statusOption.value);
													setOpenDropdown('');
												}}
											>
												{statusOption.value}
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
					)}
				/>

				<Controller
					name="selectedDurationOption"
					control={control}
					defaultValue={durationOptions[0].label}
					render={({ field }) => (
						<div className="col-md-4">
							<div className="pb-3 pt-5 pt-sm-0 fs-7 fw-bold">
								<FormattedMessage id="RESERVATIONS.DETAILS.MODAL_BODY.DURATION" />
							</div>
							<div className={clsx('custom-select', { open: openDropdown === 'duration' })}>
								{/* <div className="custom-select-icon icon-left">
									<i className="fa-solid fa-calendar" />
								</div> */}
								<div className="select-wrapper">
									<div className="select-header icon-left p-4" onClick={() => toggleDropdown('duration')} ref={durationRef}>
										<span className="selected-option fs-6 fw-bold">{field.value}</span>
										<i className="fa-solid fa-chevron-down arrow-icon" />
									</div>
									<ul className={clsx('select-options fs-6 fw-bold', { 'd-none': openDropdown !== 'duration' })}>
										{durationOptions.map((durationOption) => (
											<li
												key={durationOption.value}
												className={clsx('option', { active: field.value === durationOption.label })}
												onClick={() => {
													field.onChange(durationOption.label);
													setOpenDropdown('');
												}}
											>
												{durationOption.label}
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
					)}
				/>
			</div>

			<div className="row">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="pb-5 mb-0 fs-2 fw-bold">
						<FormattedMessage id="RESERVATION.DETAILS_OPTIONS.GUEST_DETAILS" />
					</div>
				</div>
			</div>
			<div className="row pb-5">
				<div className="col-12">
					<div className="input-group guest-search">
						<i className="fa-solid fa-magnifying-glass search-icon" />
						<input
							type="text"
							className="form-control p-4"
							data-role="tagsinput"
							placeholder="Search Guest by Name or Phone"
							value={search}
							onChange={handleSearchChange}
						/>
					</div>
				</div>
			</div>

			{isContactSuccess && (
				<div className="row mt-2">
					{availableContacts.contacts.length > 0 && (
						<div className="list-group">
							<h3>Contacts</h3>
							{availableContacts.contacts.map((contact) => (
								<div key={contact.id} className="list-group-item list-group-item-action" onClick={() => handleContactClick(contact)}>
									{contact.name} - {contact.phone}
								</div>
							))}
						</div>
					)}
					{availableContacts.users.length > 0 && (
						<div className="list-group">
							<div className="row">
								<div className="col-12">
									<div className="separator my-5" />
								</div>
							</div>
							<h3>
								<FormattedMessage id="RESERVATION.DETAILS_OPTIONS.USERS" />
							</h3>
							{availableContacts.users.map((user) => (
								<div key={user.id} className="list-group-item list-group-item-action" onClick={() => handleUserClick(user)}>
									{user.fname} {user.lname} - {user.mobile}
								</div>
							))}
						</div>
					)}
				</div>
			)}

			<div className="p-3 generic-border">
				<div className="row">
					<div className="col-12 col-sm-6">
						<div className="input-group">
							<label htmlFor="guest-fname" className="pb-3 mb-0 fs-7 fw-bold">
								<FormattedMessage id="RESERVATION.ADD_GUEST.FIRSTNAME" />
								<sup>*</sup>
							</label>
							<input
								type="text"
								id="guest-fname"
								name="guest-fname"
								aria-labelledby="guest-fname"
								className="form-control fs-6 p-4"
								placeholder="Nikos"
								data-np-autofill-type="firstname"
								{...register('firstname')}
								onChange={() => setGuestType('new_contact')}
								disabled={disable}
							/>
							{errors?.firstname && (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{errors.firstname.message}</span>
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="col-12 col-sm-6">
						<div className="input-group">
							<label htmlFor="guest-lname" className="pb-3 pt-5 pt-sm-0 fs-7 fw-bold">
								<FormattedMessage id="RESERVATION.ADD_GUEST.LASTNAME" />
								<sup>*</sup>
							</label>
							<input
								type="text"
								id="guest-lname"
								name="guest-lname"
								aria-labelledby="guest-lname"
								className="form-control fs-6 p-4"
								placeholder="Nikos"
								data-np-autofill-type="lastname"
								{...register('lastname')}
								onChange={() => setGuestType('new_contact')}
								disabled={disable}
							/>
							{errors?.lastname && (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{errors.lastname.message}</span>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="row pt-5">
				<div className="col-12">
					<div className="row">
						<div className="col-12 col-sm-6">
							<div className="input-group">
								<label htmlFor="guest-email" className="pb-3 mb-0 fs-7 fw-bold">
									<FormattedMessage id="RESERVATION.ADD_GUEST.EMAIL" />
								</label>
								<input
									type="email"
									className="form-control p-4"
									id="guest-email"
									name="guest-email"
									aria-describedby="emailHelp"
									placeholder="n.doukas@gmail.com"
									{...register('email')}
									onChange={() => setGuestType('new_contact')}
									disabled={disable}
								/>
								{errors?.email && (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">
											<span role="alert">{errors.email.message}</span>
										</div>
									</div>
								)}
							</div>
						</div>

						<div className="col-12 col-sm-6">
							<div className="row">
								<div className="col-12">
									<div className="pb-3 pt-5 pt-sm-0 fs-7 fw-bold">
										<FormattedMessage id="RESERVATION.ADD_GUEST.PHONENUMBER" />
									</div>
								</div>
							</div>

							<div className="row">
								<Controller
									name="selectedPhoneNumberOption"
									control={control}
									defaultValue={phoneNumberOptions[0].label}
									render={({ field }) => (
										<div className="col-4">
											<div className={clsx('custom-select', { open: openDropdown === 'phoneNumber' })}>
												<div className="select-wrapper">
													<div
														className="select-header icon-left p-4"
														onClick={() => toggleDropdown('phoneNumber')}
														ref={phoneRef}
													>
														<span className="selected-option">{field.value}</span>
														<i className="fa-solid fa-chevron-down arrow-icon" />
													</div>
													<ul className={clsx('select-options fw-bold fs-6', { 'd-none': openDropdown !== 'phoneNumber' })}>
														{phoneNumberOptions.map((phoneNumberOption) => (
															<li
																key={phoneNumberOption.value}
																className={clsx('option', { active: field.value === phoneNumberOption.label })}
																onClick={() => {
																	field.onChange(phoneNumberOption.label);
																	setOpenDropdown('');
																}}
															>
																{phoneNumberOption.label}
															</li>
														))}
													</ul>
												</div>
											</div>
										</div>
									)}
								/>
								<div className="col-8">
									<div className="input-group">
										<input
											type="phone"
											className="form-control p-4"
											id="guest-phone"
											aria-describedby="phoneHelp"
											placeholder="Label"
											{...register('phoneNumber')}
											disabled={disable}
										/>
										{errors?.phoneNumber && (
											<div className="fv-plugins-message-container">
												<div className="fv-help-block">
													<span role="alert">{errors.phoneNumber.message}</span>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row pt-5">
						<div className="col-12">
							<div className="d-flex justify-content-between">
								<div className="d-flex form-check form-check-custom form-check-solid">
									<input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" defaultChecked />
									<label className="form-check-label" htmlFor="flexCheckChecked">
										<FormattedMessage id="RESERVATION.DETAILS_OPTIONS.SEND_EMAIL" />
									</label>
								</div>
								<div className="d-flex">
									<button type="button" onClick={handleResetGuestsFields} className="delete-button fw-bold fs-6 px-5 py-3">
										Reset
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>

			<div className="row pb-5">
				<div className="col-12">
					<div className="fs-2 fw-bold mb-0">
						<FormattedMessage id="RESERVATION.DETAILS_OPTIONS.OTHER_GUESTS" />
					</div>
				</div>
			</div>

			{content}

			<AddGuests
				onAddGuest={handleAddGuest}
				people={people - 1}
				guestList={guestList.length}
				openDropdownValue={dropdownValue}
				onOpenDropdownChange={handleOpenDropdownChange}
				hiddenPhoneRef={hiddenPhoneRef}
				editingGuest={editingGuest}
				setEditingGuest={setEditingGuest}
			/>
		</>
	);
};

export default ReservationDetailsOptions;
