import apiSlice from '../../app/services/apiSlice';
import { setLocalStorage, setSessionStorage } from '../../common/storage';

const authApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation({
			query: (credentials) => ({
				url: '/auth/login',
				method: 'POST',
				headers: { Authorization: `Basic ${btoa(`${credentials.email}:${credentials.password}`)}` },
			}),
			transformResponse: (responseData) => {
				setSessionStorage('user', JSON.stringify(responseData));

				const { access_token: accessToken, refresh_token: refreshToken } = responseData;

				setSessionStorage('accessToken', accessToken);
				setLocalStorage('refreshToken', refreshToken);

				return responseData;
			},
		}),
	}),
});

const { useLoginMutation } = authApiSlice;

export default useLoginMutation;
