import * as Yup from 'yup';

export enum Time {
	AM = 'AM',
	PM = 'PM',
}

export enum BlockingAreas {
	TABOL = 'Tabol',
	INSTORE = 'In-store',
	WIDGET = 'Widget',
}

export const validationSchema = Yup.object().shape({
	date: Yup.date().required('Date is required'),
	startTime: Yup.string().required('Start time is required'),
	endTime: Yup.string().required('End time is required'),
	tabol: Yup.boolean(),
	inStore: Yup.boolean(),
	widget: Yup.boolean(),
});

export type BlockingHoursItem = {
	id: string | number;
	date: Date;
	startTime: string;
	endTime: string;
	tabol: boolean;
	inStore: boolean;
	widget: boolean;
};

export type BlockingHoursItemFields = Omit<BlockingHoursItem, 'id'>;

export const initialValues: BlockingHoursItemFields = {
	date: new Date(),
	startTime: '',
	endTime: '',
	tabol: false,
	inStore: false,
	widget: false,
};
