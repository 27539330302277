import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

const forgotPasswordSchema = Yup.object().shape({
	email: Yup.string().email('Wrong email format').min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Email is required'),
});

export function ForgotPassword() {
	const {
		register,
		handleSubmit,
		formState: { errors, isValid, isSubmitting, touchedFields },
	} = useForm({
		mode: 'all',
		resolver: yupResolver(forgotPasswordSchema),
	});

	const isSuccess = false,
		isLoading = false,
		isError = false;
	const forgotPassword = () => {};
	//const [forgotPassword, { isSuccess, isLoading, isError }] = useForgotPasswordMutation();

	const onSubmit = ({ email }) => {
		const args = {
			email,
		};

		forgotPassword(args);
	};

	return (
		<div className="card card-custom overlay min-h-125px shadow-none">
			<div className="card-body d-flex flex-column p-10">
				<form
					className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
					noValidate
					id="kt_login_password_reset_form"
					onSubmit={handleSubmit(onSubmit)}
				>
					<div className="text-center mb-10">
						<h1 className="text-dark fw-bolder mb-3"><FormattedMessage id="AUTH.FORGOT.TITLE" /></h1>

						<div className="text-gray-500 fw-semibold fs-6"><FormattedMessage id="AUTH.FORGOT.DESC" /></div>
					</div>

					{isError && (
						<div className="mb-lg-15 alert alert-danger">
							<div className="alert-text font-weight-bold"><FormattedMessage id="AUTH.FORGOT.ERROR" /></div>
						</div>
					)}

					{isSuccess && (
						<div className="mb-10 bg-light-info p-8 rounded">
							<div className="text-info"><FormattedMessage id="AUTH.FORGOT.RESET.PASSWORD" /></div>
						</div>
					)}

					<div className="fv-row mb-8">
						<label className="form-label fw-bolder text-gray-900 fs-6"><FormattedMessage id="AUTH.INPUT.EMAIL" /></label>
						<input
							type="email"
							{...register('email')}
							className={clsx(
								'form-control bg-transparent',
								{ 'is-invalid': touchedFields.email && errors.email },
								{ 'is-valid': touchedFields.email && !errors.email }
							)}
							autoComplete="off"
							placeholder="admin@demo.com"
						/>
						{errors.email && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{errors.email.message}</span>
								</div>
							</div>
						)}
					</div>

					<div className="d-flex flex-wrap justify-content-center pb-lg-0">
						<button type="submit" id="kt_password_reset_submit" className="btn btn-primary me-4">
							<span className="indicator-label"><FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" /></span>
							{isLoading && (
								<span className="indicator-progress">
									<FormattedMessage id="AUTH.LOGIN.LOADING" />
									<span className="spinner-border spinner-border-sm align-middle ms-2" />
								</span>
							)}
						</button>
						<Link to="/auth/login">
							<button
								type="button"
								id="kt_login_password_reset_form_cancel_button"
								className="btn btn-light"
								disabled={isSubmitting || !isValid}
							>
								<FormattedMessage id="GENERAL.CANCEL" />
							</button>
						</Link>{' '}
					</div>
				</form>
			</div>
		</div>
	);
}
