import { Dropdown } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import useConfirmationModal from 'src/components/modal/useConfirmationModal';
import Table from 'src/components/table/Table';


const GuestsTable = ({ onEditGuest, onDeleteGuest }) => {
	const [confirmAction, confirmationModal] = useConfirmationModal();

	return (
		<div className="card shadow mb-5 mb-xl-8">
			<div className="card-body py-2">
				<Table>
					<Table.Column field="firstname" sortable>
					<FormattedMessage id="RESERVATION.GUEST_TABLE.FIRSTNAME"/>
					</Table.Column>
					<Table.Column field="lastname" sortable>
					<FormattedMessage id="RESERVATION.GUEST_TABLE.LASTNAME"/>
					</Table.Column>
					<Table.Column field="email"><FormattedMessage id="RESERVATION.GUEST_TABLE.EMAIL"/></Table.Column>
					<Table.Column field="phoneNumber"><FormattedMessage id="RESERVATION.GUEST_TABLE.PHONE"/></Table.Column>
					<Table.Column
						field="action"
						body={(row) => {
							return (
								<Dropdown
									style={{
										position: 'absolute',
									}}
								>
									<Dropdown.Toggle className="btn btn-sm generic-button fw-bold" id="dropdown-basic">
									<FormattedMessage id="RESERVATION.GUEST_TABLE.ACTTION"/>
									</Dropdown.Toggle>
									<Dropdown.Menu style={{ position: 'absolute', zIndex: 1000 }}>
										<Dropdown.Item
											className="btn btn-sm"
											key={`${row.id}-edit`}
											icon="/media/icons/duotune/art/art005.svg"
											onClick={() => onEditGuest(row.id)}
										>
											<FormattedMessage id="GENERAL.EDIT"/>
										</Dropdown.Item>
										<Dropdown.Item
											className="btn btn-sm"
											key={`${row.id}-delete`}
											onClick={() => confirmAction(() => onDeleteGuest(row.id))}
										>
											<FormattedMessage id="GENERAL.DELETE"/>
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							);
						}}
					>
						<FormattedMessage id="GENERAL.ACTION"/>
					</Table.Column>
				</Table>
			</div>
			{confirmationModal}
		</div>
	);
};

export default GuestsTable;
