import { Modal as RBModal } from 'react-bootstrap';

import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import useModal from './useModal';

const Modal = () => {
	const { maxWidth, title, onClose } = useModal();

	return (
		<RBModal
			id={`kt_modal_${title}`}
			tabIndex={-1}
			aria-hidden
			dialogClassName={`modal-dialog modal-dialog-centered ${maxWidth}`}
			show
			onHide={onClose}
			backdrop>
			<ModalHeader />
			<ModalBody />
			<ModalFooter />
		</RBModal>
	);
};

export default Modal;
