import clsx from 'clsx';
import { DeepMap, FieldError, FieldValues } from 'react-hook-form';
import ReactSelect from 'react-select';
import { customStyles } from 'src/common/inputStyles';

type SelectOption = {
	value: string;
	label: string;
};

type SelectProps = {
	name?: string;
	value: SelectOption | Array<SelectOption>;
	title?: string;
	options: Array<SelectOption>;
	defaultValue?: SelectOption | Array<SelectOption>;
	onChange: (options) => void;
	onInputChange?: (inputChange: string) => void;
	isLoading?: boolean;
	isDisabled?: boolean;
	isClearable?: boolean;
	isSearchable?: boolean;
	isMulti?: boolean;
	required?: boolean;
	errors?: DeepMap<FieldValues, FieldError>;
	responsiveTitle?: boolean;
	filtersTitle?: boolean;
	horizontalSelect?: boolean;
	horizontalPhone?: boolean;
};

const Select: React.FC<SelectProps> = ({
	name,
	value,
	isMulti,
	isDisabled,
	isSearchable,
	isClearable,
	onChange,
	title,
	options,
	defaultValue,
	onInputChange,
	isLoading,
	errors,
	required = false,
	responsiveTitle = false,
	filtersTitle = false,
	horizontalSelect = false,
	horizontalPhone = false,
}) => {
	return (
		<div className={clsx({ 'row align-items-center': horizontalSelect })} onClick={(e) => e.stopPropagation()}>
			{!horizontalPhone && title && (
				<div className={clsx({ 'col-12 col-sm-3': horizontalSelect })}>
					<div
						className={clsx('pb-3 fs-7 fw-bold', {
							'pt-5 pt-sm-0': responsiveTitle,
							'py-3': filtersTitle,
							required: required,
						})}>
						{title}
					</div>
				</div>
			)}

			<div className={clsx({ 'col-12 col-sm-9': horizontalSelect })}>
				<ReactSelect
					options={options}
					defaultValue={defaultValue}
					value={value}
					onChange={onChange}
					onInputChange={onInputChange}
					isLoading={isLoading}
					styles={customStyles}
					closeMenuOnSelect={true}
					isMulti={isMulti}
					isDisabled={isDisabled}
					isClearable={isClearable}
					isSearchable={isSearchable}
				/>
			</div>
		</div>
	);
};

export default Select;

export type { SelectOption, SelectProps };
