import { useFieldArray } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { KTSVG } from '../../../_metronic/helpers';
import { Text } from '../';

type BankInfoFields = {
	id: string;
	details?: string;
	disabled?: boolean;
	responsiveTitle?: boolean;
};

const BankInfo = ({ control, register, name, errors, disabled = false, responsiveTitle }) => {
	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});

	const bankInfoFields = fields as Array<BankInfoFields>;

	const appendItem = () => append({});

	const intl = useIntl();

	return (
		<>
			{bankInfoFields.map((field, index) => (
				<div key={field.id} className="row pb-5">
					<div className="col-12 col-sm-5">
						<div className="input-group">
							<Text
								register={register}
								name={`${name}.${index}.bank_name`}
								title={intl.formatMessage({ id: 'LEAD.INVOICE.TAB.BANK_NAME' })}
								errors={errors}
								disabled={disabled}
							/>
						</div>
					</div>
					<div className="col-12 col-sm-5">
						<div className="input-group">
							<Text
								register={register}
								name={`${name}.${index}.details`}
								title={intl.formatMessage({ id: 'LEAD.INVOICE.TAB.BANK_DETAILS' })}
								errors={errors}
								disabled={disabled}
								responsiveTitle={true}
							/>
						</div>
					</div>
					{!disabled && (
						<div className="col-12 col-sm-2 pt-5 pt-sm-0 d-flex align-items-end">
							<button
								onClick={() => remove(index)}
								type="button"
								className="btn btn-light-danger btn-color-danger w-100 d-flex justify-content-center align-items-center"
							>
								<KTSVG className="svg-icon-1" path="/media/icons/duotune/general/gen027.svg" />
								<div className="fs-7">
									<FormattedMessage id="GENERAL.DELETE" />
								</div>
							</button>
						</div>
					)}
				</div>
			))}
			{!disabled && (
				<div className="row ">
					<div className="col-12 col-sm-3">
						<button type="button" className="generic-button fw-bold fs-6 w-100 p-4" onClick={appendItem}>
							<FormattedMessage id="CONTACTS.ADDBANK" />
						</button>
					</div>
				</div>
			)}
		</>
	);
};

export default BankInfo;
