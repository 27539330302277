import { useContext } from 'react';

import TableContext from './context';

const TableSelection = ({ children }) => {
	const { selectedRows } = useContext(TableContext);

	if (!selectedRows.length) {
		return;
	}

	return (
		<div className="card-header justify-content-end">
			<div className="d-flex justify-content-end">
				<div className="px-4 d-flex align-items-center">
					<span className="fw-bold me-3">Selected: {selectedRows.length}</span>
					{children}
				</div>
			</div>
		</div>
	);
};

export default TableSelection;
