import { formatDate } from "src/components/input/Datepicker";
import { SelectOption } from "src/components/input/Select";

import { BlockingHoursItem } from "./schema";

const nextDate = new Date();
nextDate.setDate(nextDate.getDate() + 2);

export const blockingData: BlockingHoursItem[] = [
  {
    id: 1,
    date: new Date(),
    startTime: '11:00',
    endTime: '12:00',
    tabol: true,
    inStore: true,
    widget: false,
  },
  {
    id: 2,
    date: new Date(),
    startTime: '15:00',
    endTime: '16:00',
    tabol: true,
    inStore: true,
    widget: true,
  },
  {
    id: 3,
    date: nextDate,
    startTime: '11:00',
    endTime: '13:00',
    tabol: true,
    inStore: false,
    widget: true,
  }
]

export const blockingHours: { [key: string]: SelectOption[] } = {
  [formatDate(new Date())]: [
    { value: '08:00', label: '08:00' },
    { value: '09:00', label: '09:00' },
    { value: '10:00', label: '10:00' },
    { value: '11:00', label: '11:00' },
    { value: '12:00', label: '12:00' },
    { value: '13:00', label: '13:00' },
    { value: '14:00', label: '14:00' },
    { value: '15:00', label: '15:00' },
    { value: '16:00', label: '16:00' },
    { value: '17:00', label: '17:00' },
    { value: '18:00', label: '18:00' },
  ],
  [formatDate(nextDate)]: [
    { value: '13:00', label: '13:00' },
    { value: '14:00', label: '14:00' },
    { value: '15:00', label: '15:00' },
    { value: '16:00', label: '16:00' },
    { value: '17:00', label: '17:00' },
    { value: '18:00', label: '18:00' },
    { value: '19:00', label: '19:00' },
    { value: '20:00', label: '20:00' },
    { value: '21:00', label: '21:00' },
    { value: '22:00', label: '22:00' },
    { value: '23:00', label: '23:00' },
  ]
};
