import clsx from 'clsx';
import { FC } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useCheckReadPermissions } from 'src/components/permissions/useCheckPermissions';

import { checkIsActive, KTSVG, WithChildren } from '../../../../helpers';
import { useLayout } from '../../../core';

type Props = {
	to: string;
	title: string;
	icon?: string;
	disabled?: boolean;
	fontIcon?: string;
	hasBullet?: boolean;
	callback?: () => void;
};

const SidebarMenuItem: FC<Props & WithChildren> = ({ children, to, title, icon, fontIcon, callback, disabled = false, hasBullet = false }) => {
	const { pathname } = useLocation();
	const isActive = checkIsActive(pathname, to);
	const { config } = useLayout();
	const { app } = config;

	const handleCallback = () => {
		if (!callback) return;

		callback();
	};

	const cleanTo = to.replace(/^\//, '');
	const canRead = useCheckReadPermissions(cleanTo).hasAccess;
	if (!canRead) {
		return null;
	}

	return (
		<div className="menu-item" onClick={handleCallback}>
			<Link className={clsx('menu-link without-sub', { active: isActive })} to={disabled ? '#' : to}>
				{hasBullet && (
					<span className="menu-bullet">
						<span className="bullet bullet-dot"></span>
					</span>
				)}
				{icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
					<span className="menu-icon">
						{' '}
						<KTSVG path={icon} className="svg-icon-2" />
					</span>
				)}
				{fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
				<span className="menu-title">{title}</span>
			</Link>
			{children}
		</div>
	);
};

export { SidebarMenuItem };
