import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

const initialState = {
	user: null,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logOut: (state) => {
			state.user = null;
		},
	},
});

const { logOut } = authSlice.actions;


export { logOut };

export default authSlice.reducer;
