import * as Yup from 'yup';

export const partySizeOptions = [
	{ id: 2, value: '2', label: '2' },
	{ id: 3, value: '3', label: '3' },
	{ id: 4, value: '4', label: '4' },
];

export const sourceOptions = [
	{ value: 'walk-in', label: 'Walk-In' },
	{ value: 'phone', label: 'Phone' },
];

export const statusOptions = [
	{ value: 'pending', label: 'pending' },
	{ value: 'confirmed', label: 'confirmed' },
	{ value: 'arrived', label: 'arrived' },
	{ value: 'completed', label: 'completed' },
	{ value: 'canceled', label: 'canceled' },
];

export const durationOptions = [
	{ value: '01:30', label: '01:30' },
	{ value: '02:00', label: '02:00' },
	{ value: '02:30', label: '02:30' },
];

export const phoneNumberOptions = [
	{ value: '+30', label: '+30' },
	{ value: '+40', label: '+40' },
	{ value: '+49', label: '+49' },
];

export const headers = [
	{
		name: 'FULL NAME',
	},
	{
		name: 'EMAIL',
	},
	{
		name: 'PHONE',
	},
	{
		name: 'ACTION',
	},
];

export const validationSchema = Yup.object({
	firstname: Yup.string().required('First Name is required'),
	lastname: Yup.string().required('Last Name is required'),
	phoneNumber: Yup.string()
		.required('Phone number is required')
		.test('phoneNumber', 'Phone Number must be 10 digits', (value) => {
			if (!value) return true;
			return /^\d{10}$/.test(value);
		}),
	email: Yup.string().email('Enter a valid email').nullable().notRequired(),
});
