import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { format } from 'date-fns';
import { useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import useModal from '../../../components/modal/useModal';
import { selectActiveBusiness, selectActiveStore, selectBusinesses } from '../../../reducers/business/businessSlice';
import { useCreateReservationMutation, useEditReservationMutation, useGetReservationQuery } from '../../../reducers/reservationApiSlice';
import ReservationDetailsOptions from '../reservationPages/ReservationDetailsOptions';
import { validationSchema } from '../reservationPages/ResOptions';

const initialValues = {
	teamNotes: '',
	messages: '',
};

const ReservationModalBody = () => {
	const { ref, id, editMode, onClose } = useModal();

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
		reset,
		setValue,
		getValues,
		watch,
	} = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema),
		shouldFocusError: false,
		shouldUseNativeValidation: false,
	});

	const [startDate, setStartDate] = useState(new Date());
	const [availableTime, setAvailableTime] = useState('');
	const [selectedServiceAreaId, setSelectedServiceAreaId] = useState('');
	const [selectedTablesId, setSelectedTablesId] = useState([]);
	const [selectedPreference, setSelectedPreference] = useState([]);
	const [newGuest, setNewGuest] = useState([]);
	const [guestType, setGuestType] = useState('new_contact');
	const [guestId, setGuestId] = useState('');

	const [createReservation] = useCreateReservationMutation();
	const [editReservation] = useEditReservationMutation();

	const formattedDate = format(startDate, 'yyyy-MM-dd');

	const handleStoreIdChange = (newStoreId) => {
		setStoreId(newStoreId);
	};

	const handlePreferenceChange = (value) => {
		setSelectedPreference(value);
	};

	const handleAreaIdChange = (newAreaId) => {
		setSelectedServiceAreaId(newAreaId);
	};

	useImperativeHandle(ref, () => ({
		onSubmit: () => handleSubmit(onSubmit)(),
	}));

	// Fetch to get stores

	const { _id: businessId } = useSelector(selectActiveBusiness);

	const businesses = useSelector(selectBusinesses);
	const activeBusinessStores = businesses.find((business) => business._id === businessId)?.stores;
	let stores = [];
	if (activeBusinessStores) {
		stores = activeBusinessStores.map((store) => ({
			id: store._id,
			value: store.title,
			label: store.title,
		}));
	}

	const { _id: activeStoreId } = useSelector(selectActiveStore);
	const [storeId, setStoreId] = useState(activeStoreId);

	const args = { path: { id } };
	const { data: reservation, isSuccess: isReservationSuccess } = useGetReservationQuery(args, { skip: !editMode });
	const [editModeFlag, setEditModeFlag] = useState(true);

	useEffect(() => {
		if (isReservationSuccess && editModeFlag) {
			setStartDate(new Date(reservation.reservation.date));
			setValue('messages', reservation.reservation.message);
			setValue('teamNotes', reservation.reservation.comments);
			setEditModeFlag(false);
		}
	}, [editModeFlag, isReservationSuccess]);

	useEffect(() => {
		if (editMode && isReservationSuccess) {
			const formData = { ...reservation };

			reset(formData);
		}
	}, [editMode, isReservationSuccess, reservation, reset]);

	const onSubmit = async (data) => {
		const inputs = {
			store_id: storeId,
			date: `${formattedDate} ${availableTime}`,
			people: data.selectedPartySizeOption,
			area_id: selectedServiceAreaId,
			table_ids: selectedTablesId,
			comments: data.teamNotes,
			message: data.messages,
			duration: data.selectedDurationOption,
			preferences: selectedPreference.map((pref) => pref.value),
			source: data.selectedSourceOption,
			guests: [],
		};

		if (editMode) {
			inputs.status = data.selectedStatusOption;
			delete inputs.source;
		}

		if (guestType === 'contact') {
			inputs.host = { type: guestType, id: guestId };
		} else if (guestType === 'tabol_user') {
			inputs.host = { type: guestType, id: guestId };
		} else {
			inputs.host = {
				type: 'new_contact',
				fname: data.firstname,
				lname: data.lastname,
				phone: `${data.selectedPhoneNumberOption}${data.phoneNumber}`,
				email: data.email,
			};
		}

		newGuest.forEach((guest) => {
			if (guest.type === 'new_contact') {
				inputs.guests.push({
					type: guest.type,
					fname: guest.firstname,
					lname: guest.lastname,
					email: guest.email,
					phone: `${guest.selectedGuestPhoneNumberOption}${guest.phoneNumber}`,
				});
			} else {
				inputs.guests.push({
					type: guest.type,
					id: guest.id,
				});
			}
		});

		editMode ? await editReservation({ path: { id }, inputs }) : await createReservation(inputs);
		onClose();
	};

	return (
		<div className="modal-content">
			<form data-np-autofill-type="identity" data-np-checked="1" data-np-watching="1" ref={ref}>
				<ul className="nav nav-tabs nav-line-tabs d-flex justify-content-center mb-5 fs-3">
					<li className="nav-item">
						<a className="nav-link active" data-bs-toggle="tab" href="#kt_tab_details" name="details">
							<FormattedMessage id="RESERVATIONS.MODAL_BODY.DETAILS" />
						</a>
					</li>
					<li className="nav-item">
						<a className="nav-link" data-bs-toggle="tab" href="#kt_tab_payments" name="payments">
							<FormattedMessage id="RESERVATIONS.MODAL_BODY.PAYMENTS" />
						</a>
					</li>
				</ul>
				<div className="row pb-5">
					<div className="col-12">
						<div className="mb-0 fs-2 fw-bold">
							<FormattedMessage id="RESERVATIONS.MODAL_BODY.RES_DETAILS" />
						</div>
					</div>
				</div>
				<ReservationDetailsOptions
					control={control}
					register={register}
					setValue={setValue}
					errors={errors}
					onSelectPreferenceChange={handlePreferenceChange}
					selectedTablesId={selectedTablesId}
					setSelectedTablesId={setSelectedTablesId}
					setNewGuest={setNewGuest}
					stores={stores}
					onStoreIdChange={handleStoreIdChange}
					storeId={storeId}
					selectedServiceAreaId={selectedServiceAreaId}
					onAreaIdChange={handleAreaIdChange}
					startDate={startDate}
					setStartDate={setStartDate}
					formattedDate={formattedDate}
					availableTime={availableTime}
					setAvailableTime={setAvailableTime}
					setGuestType={setGuestType}
					setGuestId={setGuestId}
					businessId={businessId}
					isReservationSuccess={isReservationSuccess}
					reservation={reservation}
				/>
				<div className="row">
					<div className="col-12">
						<div className="separator my-5" />
					</div>
				</div>
				<div className="row pb-5">
					<div className="col-12">
						<div className="mb-0 fs-2 fw-bold">
							<FormattedMessage id="RESERVATIONS.MODAL_BODY.TEAM_NOTES" />
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="pb-3 fs-7 fw-bold">
							<FormattedMessage id="RESERVATIONS.MODAL_BODY.NOTES" />
						</div>
						<textarea
							className="full-width-textarea fs-6 p-4"
							{...register('teamNotes')}
							rows="4"
							cols="50"
							aria-labelledby="teamNotes"
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="separator my-5" />
					</div>
				</div>
				<div className="row pb-5">
					<div className="col-12">
						<div className="mb-0 fs-2 fw-bold">
							<FormattedMessage id="RESERVATIONS.MODAL_BODY.MESSAGE_TO_GUEST" />
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="pb-3 fs-7 fw-bold">
							<FormattedMessage id="RESERVATIONS.MODAL_BODY.MESSAGE" />
						</div>
						<textarea className="full-width-textarea fs-6 p-4" {...register('messages')} rows="4" cols="50" aria-labelledby="messages" />
					</div>
				</div>
			</form>
			<DevTool control={control} />
		</div>
	);
};

export default ReservationModalBody;
