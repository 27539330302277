import { useMemo, useState } from 'react';

import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../common/constants';

const usePagination = (page, size, count) => {
	const [currentPage, setCurrentPage] = useState(page);

	const totalPages = Math.ceil(count / size);

	const actions = useMemo(
		() => ({
			goTo: (targetPage) => setCurrentPage(targetPage),
			goBack: () => setCurrentPage(currentPage - 1),
			goForward: () => setCurrentPage(currentPage + 1),
			goToFirst: () => setCurrentPage(1),
		}),
		[currentPage]
	);

	return { currentPage, totalPages, actions };
};

const usePaging = (data = [], count = 0, page = DEFAULT_PAGE, size = DEFAULT_SIZE) => {
	count = count ? count : data.length ?? 0;
	const paging = usePagination(page, size, count);
	let dataPage = data.length;
	if (data.length > 0) {
		dataPage = paging.currentPage % data.length || data.length;
	}

	const startIndex = data.length <= size ? 0 : dataPage * size - size;
	const endIndex = startIndex + size;
	const pagedItems = data.slice(startIndex, endIndex);

	const showing = {
		total: count,
		from: (paging.currentPage - 1) * size + 1,
		to: pagedItems.length < size ? count : paging.currentPage * size,
	};

	return [showing, paging, pagedItems];
};

export default usePaging;
