import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import { useState } from 'react';
import { Control, Controller, DeepMap, FieldError, FieldValues } from 'react-hook-form';

import Select from './Select';

const phoneNumberOptions = [
	{ value: '+30', label: '+30' },
	{ value: '+40', label: '+40' },
	{ value: '+49', label: '+49' },
];

type Props = {
	control: Control<any>;
	name: string;
	title?: string;
	errors: DeepMap<FieldValues, FieldError>;
	disabled?: boolean;
	horizontalPhone?: boolean;
};

const PhoneNumber: React.FC<Props> = ({ control, name, title, errors, disabled = false, horizontalPhone = false }) => {
	const defaultValue = phoneNumberOptions[0];
	const [prefix, setPrefix] = useState(defaultValue);

	const handleNumberChange = (newPrefix, number) => {
		if (number !== '') {
			return newPrefix.value + number;
		}
		return '';
	};

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={''}
			render={({ field: { value, onChange } }) => {
				// const prefix = phoneNumberOptions.find((o) => value.startsWith(o.value)) || defaultValue;
				const number = value.replace(prefix.value, '');
				return (
					<>
						<div className={clsx({ 'row align-items-center': horizontalPhone })}>
							<div className={clsx({ 'col-12 col-sm-3': horizontalPhone })}>
								<div className="form-label pb-3 mb-0 fw-bold fs-7">{title}</div>
							</div>
							<div className={clsx({ 'col-12 col-sm-9': horizontalPhone })}>
								<div className="input-group">
									<div className="row">
										<div className="col-4 col-sm-5">
											<Select
												value={prefix}
												onChange={(newPrefix) => {
													setPrefix(newPrefix);
													onChange(handleNumberChange(newPrefix, number));
												}}
												// title={title}
												options={phoneNumberOptions}
												// defaultValue={defaultValue}
												isDisabled={disabled}
												horizontalPhone={horizontalPhone}
											/>
										</div>

										<div className="col-8 col-sm-7">
											{/* <label className="form-label pb-3 mb-0 fw-bold">{title}</label> */}
											<input
												name="number"
												type="text"
												className="form-control fs-6 fw-bold p-4"
												placeholder={'697xxxxxxx'}
												value={number}
												onChange={(e) => onChange(handleNumberChange(prefix, e.target.value))}
												disabled={disabled}
											/>
										</div>
										{errors && (
											<ErrorMessage
												errors={errors}
												name={name}
												render={({ message }) => (
													<div className="fv-plugins-message-container">
														<div className="fv-help-block">
															<span role="alert">{message}</span>
														</div>
													</div>
												)}
											/>
										)}
									</div>
								</div>
							</div>
						</div>
					</>
				);
			}}
		/>
	);
};

export default PhoneNumber;
