import clsx from 'clsx';
import { FC } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { checkIsActive, KTSVG } from '../../../../helpers';

interface MenuItemProps {
	to: string;
	title: string;
	icon?: string;
	classes?: string;
	fontIcon?: string;
	hasArrow?: boolean;
	hasBullet?: boolean;
}

const MenuItem: FC<MenuItemProps> = ({ to, title, icon, fontIcon, classes, hasArrow = false, hasBullet = false }) => {
	const { pathname } = useLocation();

	return (
		<div className="menu-item mx-3">
			<Link
				className={clsx(
					'menu-link py-3',
					{
						'menu-here': checkIsActive(pathname, to),
					},
					classes
				)}
				to={to}>
				{hasBullet && (
					<span className="menu-bullet">
						<span className="bullet bullet-dot" />
					</span>
				)}

				{icon && (
					<span className="menu-icon">
						<KTSVG path={icon} className="svg-icon-2" />
					</span>
				)}

				{fontIcon && (
					<span className="menu-icon">
						<i className={clsx('bi fs-3', fontIcon)} />
					</span>
				)}

				<span>{title}</span>

				{hasArrow && <span className="menu-arrow"></span>}
			</Link>
		</div>
	);
};

export { MenuItem };
