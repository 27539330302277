import apiSlice from '../app/services/apiSlice';
import prepareURL from '../common/prepareURL';

const basePath = '/reservations';

const reservationApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		reservation: builder.query({
			query: (args) => prepareURL(basePath, args),
			providesTags: (result, error) => {
				const tags = [{ type: 'Reservation', id: 'PARTIAL-LIST' }];

				if (!error && result?.reservations && result.reservations.length > 0) {
					const reservationTags = result.reservations.map(({ _id: id }) => ({ type: 'Reservation', id }));
					return tags.concat(reservationTags);
				}

				return tags;
			},
		}),
		getReservation: builder.query({
			query: (args) => prepareURL(basePath + '/{id}', args),
		}),
		createReservation: builder.mutation({
			query: (inputs) => ({
				url: basePath,
				method: 'POST',
				body: inputs,
			}),
			invalidatesTags: ['Reservation'],
			refetchOn: 'fulfilled',
		}),
		editReservation: builder.mutation({
			query: (args) => ({
				url: prepareURL(`${basePath}/{id}`, args),
				method: 'PUT',
				body: args.inputs,
			}),
			invalidatesTags: (result, error, args) => {
				const { success } = result;
				if (success && error === undefined) {
					const { id } = args.path;

					return [
						{ type: 'Reservations', id },
						{ type: 'Reservations', id: 'PARTIAL-LIST' },
					];
				}
			},
		}),
		deleteReservation: builder.mutation({
			query: (args) => ({
				url: prepareURL(`${basePath}/{id}`, args),
				method: 'DELETE',
			}),
			invalidatesTags: (result, error, args) => {
				const { success } = result;
				if (success && error === undefined) {
					const { id } = args.path;

					return [
						{ type: 'Reservation', id },
						{ type: 'Reservation', id: 'PARTIAL-LIST' },
					];
				}
			},
			refetchOn: 'fulfilled',
		}),
		getStoreAvailability: builder.query({
			query: (args) => prepareURL(basePath + '/store/{id}/availability', args),
		}),
	}),
});

export const {
	useReservationQuery,
	useGetReservationQuery,
	useCreateReservationMutation,
	useEditReservationMutation,
	useDeleteReservationMutation,
	useGetStoreAvailabilityQuery,
} = reservationApiSlice;
