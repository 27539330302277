import './_metronic/assets/sass/style.scss';
import './_metronic/assets/sass/plugins.scss';
import './_metronic/assets/sass/style.react.scss';

import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import AppRoutes from './app/routing/AppRoutes';
import { useGetAppSectionsQuery } from './app/services/apiSlice';
import store from './app/store';

const container = document.getElementById('root');
export const DataProvider = ({ children }: { children: React.ReactNode }) => {
	useGetAppSectionsQuery({});
	return <>{children}</>;
};

if (container) {
	const root = createRoot(container);

	root.render(
		<StrictMode>
			<Provider store={store}>
				<DataProvider>
					<BrowserRouter>
						{/* <ErrorBoundary> */}
						<AppRoutes />
						{/* </ErrorBoundary> */}
					</BrowserRouter>
				</DataProvider>
			</Provider>
		</StrictMode>
	);
}
