import { createSlice } from '@reduxjs/toolkit';

export enum TabolRoles {
	ADMIN = 'ADMIN',
	USER = 'USER',
	CUSTOMER = 'CUSTOMER',
}

interface AppState {
	apiError: string | null;
	role: TabolRoles | null;
	sidebarExpanded: boolean;
}

const initialAppState: AppState = {
	apiError: null,
	role: TabolRoles.USER,
	sidebarExpanded: true,
};

const appSlice = createSlice({
	name: 'app',
	initialState: initialAppState,
	reducers: {
		setApiError: (state, action) => {
			state.apiError = action.payload;
		},
		clearApiError: (state) => {
			state.apiError = null;
		},
		setRole: (state, action) => {
			state.role = action.payload;
		},
		setSidebarExpanded: (state, action) => {
			state.sidebarExpanded = action.payload;
		},
	},
});

const { setApiError, clearApiError, setRole, setSidebarExpanded } = appSlice.actions;
export { clearApiError, setApiError, setRole, setSidebarExpanded };

export const selectSidebarExpanded = ({ app }) => app.sidebarExpanded;
export default appSlice.reducer;
