import { useContext } from 'react';

import TableContext from './context';

// hides if there are selected rows
const TableToolbar = ({ children }) => {
	const { selectedRows } = useContext(TableContext);

	if (selectedRows.length) {
		return;
	}

	return <div className="card-header card-header-stretch">{children}</div>;
};

TableToolbar.Left = ({ children }) => (
	<div className="card-toolbar align-items-start flex-column">
		<div className="py-6 px-4">{children}</div>
	</div>
);

TableToolbar.Right = ({ children }) => (
	<div className="card-toolbar">
		<div className="py-6 px-4">{children}</div>
	</div>
);

export default TableToolbar;
