import clsx from 'clsx';

import { KTSVG } from '../../../../helpers';

type MenuButtonProps = {
	title: string;
	icon?: string;
	fontIcon?: string;
	classname: string;
	onClick: () => void;
};

const MenuButton = ({ onClick, title, icon, fontIcon, classname }: MenuButtonProps) => {
	return (
		<div className="menu-item d-flex mx-3">
			<a href="#" className={clsx('menu-link me-0 py-3', classname)} onClick={onClick}>
				{icon && (
					<span className="menu-icon">
						<KTSVG path={icon} className="svg-icon-2" />
					</span>
				)}

				{fontIcon && (
					<span className="menu-icon">
						<i className={clsx('bi fs-3', fontIcon)} />
					</span>
				)}

				<span className="">{title}</span>
			</a>
		</div>
	);
};

export { MenuButton };
