import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { useLayout } from '../../../core';
import { usePageData } from '../../../core/PageData';

const PageTitle = () => {
	const { config, classes } = useLayout();
	const appPageTitleDirection = config.app?.pageTitle?.direction;
	const { pageTitle, pageDescription, pageBreadcrumbs } = usePageData();

	return (
		<div
			id="kt_page_title"
			data-kt-swapper="true"
			data-kt-swapper-mode="prepend"
			data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
			className={clsx('page-title d-flex flex-wrap me-3', classes.pageTitle.join(' '), config.app?.pageTitle?.class, {
				'flex-column justify-content-center': appPageTitleDirection === 'column',
				'align-items-center': appPageTitleDirection !== 'column',
			})}
		>
			{config.app?.pageTitle?.display && pageTitle && (
				<h1
					className={clsx('page-heading d-flex text-dark fw-bold fs-3 my-0', {
						'flex-column justify-content-center': appPageTitleDirection,
						'align-items-center': !appPageTitleDirection,
					})}
				>
					{pageDescription || pageTitle}
				</h1>
			)}

			{pageBreadcrumbs && pageBreadcrumbs.length > 0 && config.app?.pageTitle && config.app?.pageTitle?.breadCrumb && (
				<>
					{config.app?.pageTitle?.direction === 'row' && <span className="h-20px border-gray-300 border-start mx-4" />}

					<ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0">
						{Array.from(pageBreadcrumbs).map((item) => (
							<li
								key={`${item.path}-${item.title}`}
								className={clsx('breadcrumb-item', {
									'text-dark': item.isActive,
									'text-muted': !item.isActive,
								})}
							>
								{item.isActive ? (
									<Link className="text-muted text-hover-primary" to={item.path}>
										{item.title}
									</Link>
								) : (
									<span className="text-muted">{item.title}</span>
								)}
								<span className="bullet bg-gray-400 w-5px h-2px ms-2" />
							</li>
						))}
						<li className="breadcrumb-item text-dark">{pageTitle}</li>
					</ul>
				</>
			)}
		</div>
	);
};

export { PageTitle };
