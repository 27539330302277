import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { setSidebarExpanded } from 'src/reducers/appSlice';

import { SidebarMenuItem } from './SidebarMenuItem';
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub';

const SidebarMenuMain = () => {
	const intl = useIntl();
	const dispatch = useDispatch();

	return (
		<>
			<SidebarMenuItem
				to="/dashboard"
				icon="/media/icons/duotune/art/art002.svg"
				title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
				fontIcon="bi-app-indicator"
			/>

			<SidebarMenuItemWithSub
				to="/reservations"
				title={intl.formatMessage({ id: 'MENU.RESERVATIONS' })}
				fontIcon="bi-archive"
				icon="/media/icons/duotune/general/gen014.svg"
			>
				<SidebarMenuItem to="/reservations/list" title={intl.formatMessage({ id: 'MENU.RESERVATIONS_LIST' })} hasBullet />
				<SidebarMenuItem to="/reservations/hostview" title={intl.formatMessage({ id: 'MENU.RESERVATIONS_HOSTVIEW' })} hasBullet />
			</SidebarMenuItemWithSub>

			<SidebarMenuItemWithSub
				to="/events"
				title={intl.formatMessage({ id: 'MENU.EVENTS' })}
				fontIcon="bi-archive"
				icon="/media/icons/duotune/general/gen003.svg"
			>
				<SidebarMenuItem to="/events/list" title={intl.formatMessage({ id: 'MENU.EVENTS_LIST' })} hasBullet />
				<SidebarMenuItem to="/events/categories" title={intl.formatMessage({ id: 'MENU.EVENTS_CATEGORIES' })} hasBullet />
				<SidebarMenuItem to="/events/tickets" title={intl.formatMessage({ id: 'MENU.EVENTS_TICKET_TYPES' })} hasBullet />
			</SidebarMenuItemWithSub>

			<SidebarMenuItemWithSub
				to="/contacts"
				title={intl.formatMessage({ id: 'MENU.CONTACTS' })}
				fontIcon="bi-archive"
				icon="/media/icons/duotune/communication/com005.svg"
			>
				<SidebarMenuItem to="/contacts/list" title={intl.formatMessage({ id: 'MENU.ALL_CONTACTS' })} hasBullet />
				<SidebarMenuItem to="/contacts/customers" title={intl.formatMessage({ id: 'MENU.CUSTOMERS' })} hasBullet />
				<SidebarMenuItem to="/contacts/leads" title={intl.formatMessage({ id: 'MENU.LEADS' })} hasBullet />
			</SidebarMenuItemWithSub>

			<SidebarMenuItem
				to="/elenxis"
				icon="/media/icons/duotune/ecommerce/ecm008.svg"
				title={intl.formatMessage({ id: 'MENU.VAC' })}
				fontIcon="bi-app-indicator"
			/>

			{/* <SidebarMenuItem
				to="/reports"
				icon="/media/icons/duotune/ecommerce/ecm008.svg"
				title={intl.formatMessage({ id: 'MENU.REPORTS' })}
				fontIcon="bi-app-indicator"
				callback={() => dispatch(setSidebarExpanded(true))}
			/> */}
			<SidebarMenuItemWithSub
				to="/communication"
				title={intl.formatMessage({ id: 'MENU.COMMUNICATION' })}
				fontIcon="bi-archive"
				icon="/media/icons/duotune/communication/com007.svg"
			>
				<SidebarMenuItem to="/communication/conversations" title={intl.formatMessage({ id: 'MENU.CONVERSATIONS' })} hasBullet />
				<SidebarMenuItemWithSub to="/communications/communicationTemplates" title="Communications Templates" fontIcon="bi-archive" hasBullet>
					{/*<SidebarMenuItem to="/communication/communicationTemplates/emailnotifications" title="Email Notifications" hasBullet />*/}

					<SidebarMenuItem
						to="/communication/communicationTemplates/pushnotifications"
						title={intl.formatMessage({ id: 'MENU.COMMUNICATION.PUSH' })}
						hasBullet
					/>

					<SidebarMenuItem
						to="/communication/communicationTemplates/smsnotifications"
						title={intl.formatMessage({ id: 'MENU.CONVERSATIONS.SMS' })}
						hasBullet
					/>

					<SidebarMenuItem
						to="/communication/communicationTemplates/vibernotifications"
						title={intl.formatMessage({ id: 'MENU.CONVERSATIONS.VIBER' })}
						hasBullet
					/>
				</SidebarMenuItemWithSub>
			</SidebarMenuItemWithSub>

			<SidebarMenuItemWithSub
				to="/analytics"
				title={intl.formatMessage({ id: 'MENU.ANALYTICS' })}
				fontIcon="bi-archive"
				icon="/media/icons/duotune/graphs/gra004.svg"
			>
				<SidebarMenuItem to="/analytics/reservation" title={intl.formatMessage({ id: 'MENU.ANALYTICS.RESERVATIONS' })} hasBullet />

				<SidebarMenuItem to="/analytics/sales" title={intl.formatMessage({ id: 'MENU.ANALYTICS.SALES' })} hasBullet />

				<SidebarMenuItem to="/analytics/locations" title={intl.formatMessage({ id: 'MENU.ANALYTICS.LOCATIONS' })} hasBullet />

				<SidebarMenuItem to="/analytics/customers" title={intl.formatMessage({ id: 'MENU.ANALYTICS.CUSTOMERS' })} hasBullet />

				<SidebarMenuItem to="/analytics/marketing" title={intl.formatMessage({ id: 'MENU.ANALYTICS.MARKETING' })} hasBullet />

				<SidebarMenuItem to="/analytics/forecasting" title={intl.formatMessage({ id: 'MENU.ANALYTICS.FORECASTING' })} hasBullet />

				<SidebarMenuItem to="/analytics/financial" title={intl.formatMessage({ id: 'MENU.ANALYTICS.FINANCIAL' })} hasBullet />

				<SidebarMenuItem to="/analytics/tag" title={intl.formatMessage({ id: 'MENU.ANALYTICS.TAG' })} hasBullet />
			</SidebarMenuItemWithSub>

			<SidebarMenuItemWithSub
				to="/marketing"
				title={intl.formatMessage({ id: 'MENU.MARKETING' })}
				fontIcon="bi-archive"
				icon="/media/icons/duotune/general/gen002.svg"
			>
				<SidebarMenuItem to="/marketing/feedback" title={intl.formatMessage({ id: 'MENU.FEEDBACK' })} hasBullet />
				{/* <SidebarMenuItem to="/marketing/discounts" title={intl.formatMessage({ id: 'MENU.DISCOUNTS' })} hasBullet /> */}
			</SidebarMenuItemWithSub>

			<SidebarMenuItemWithSub
				to="/settings"
				title={intl.formatMessage({ id: 'MENU.SETTINGS' })}
				fontIcon="bi-archive"
				icon="/media/icons/duotune/coding/cod001.svg"
			>
				<SidebarMenuItem to="/settings/business" title={intl.formatMessage({ id: 'MENU.ORGANIZATIONS' })} />

				<SidebarMenuItem to="/settings/stores" title={intl.formatMessage({ id: 'MENU.LOCATIONS' })} />

				<SidebarMenuItemWithSub to="/settings/list" title={intl.formatMessage({ id: 'MENU.USERS' })} fontIcon="bi-archive">
					<SidebarMenuItem to="/settings/users/list" title={intl.formatMessage({ id: 'MENU.USERS_LIST' })} hasBullet />
					<SidebarMenuItem to="/settings/users/roles" title={intl.formatMessage({ id: 'MENU.USERS_ROLES' })} hasBullet />
				</SidebarMenuItemWithSub>

				<SidebarMenuItemWithSub to="/settings/account" title={intl.formatMessage({ id: 'MENU.ACCOUNT' })} fontIcon="bi-archive">
					<SidebarMenuItem to="/settings/account/settings" title={intl.formatMessage({ id: 'MENU.ACCOUNT_SETTINGS' })} hasBullet />
				</SidebarMenuItemWithSub>

				<SidebarMenuItem to="/settings/areas" title={intl.formatMessage({ id: 'MENU.TABLE_MANAGEMENT' })} />

				<SidebarMenuItemWithSub to="/settings/tags" title={intl.formatMessage({ id: 'MENU.TAGS' })} fontIcon="bi bi-tags-fill fs-3">
					<SidebarMenuItem to="/settings/tags/list" title={intl.formatMessage({ id: 'MENU.TAGS_LIST' })} hasBullet />
					<SidebarMenuItem to="/settings/tags/categories" title={intl.formatMessage({ id: 'MENU.TAG_CATEGORIES' })} hasBullet />
				</SidebarMenuItemWithSub>

				<SidebarMenuItem to="/settings/trash" title={intl.formatMessage({ id: 'MENU.TRASH' })} />
			</SidebarMenuItemWithSub>

			<div className="sidebar-seperator my-2" />

			<SidebarMenuItem
				to="/playground"
				icon="/media/icons/duotune/art/art002.svg"
				title={intl.formatMessage({ id: 'MENU.PLAYGROUND' })}
				fontIcon="bi-app-indicator"
			/>
			<SidebarMenuItem
				to=""
				icon="/media/icons/duotune/art/art003.svg"
				title={intl.formatMessage({ id: 'MENU.FAQ' })}
				fontIcon="bi-app-indicator"
				disabled
			/>
			<SidebarMenuItem
				to=""
				icon="/media/icons/duotune/art/art004.svg"
				title={intl.formatMessage({ id: 'MENU.CONTACT' })}
				fontIcon="bi-app-indicator"
				disabled
			/>
			<SidebarMenuItem
				to=""
				icon="/media/icons/duotune/art/art005.svg"
				title={intl.formatMessage({ id: 'MENU.REQUEST' })}
				fontIcon="bi-app-indicator"
				disabled
			/>
			<SidebarMenuItem
				to=""
				icon="/media/icons/duotune/art/art006.svg"
				title={intl.formatMessage({ id: 'MENU.UPDATES' })}
				fontIcon="bi-app-indicator"
				disabled
			/>
		</>
	);
};

export default SidebarMenuMain;
