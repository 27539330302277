enum CountryCode {
	USA = '+1',
	UK = '+44',
	// Europian Union
	AUSTRIA = '+43',
	BELGIUM = '+32',
	BULGARIA = '+359',
	CROATIA = '+385',
	CYPRUS = '+357',
	CZECH_REPUBLIC = '+420',
	DENMARK = '+45',
	ESTONIA = '+372',
	FINLAND = '+358',
	FRANCE = '+33',
	GERMANY = '+49',
	GREECE = '+30',
	HUNGARY = '+36',
	IRELAND = '+353',
	ITALY = '+39',
	LATVIA = '+371',
	LITHUANIA = '+370',
	LUXEMBOURG = '+352',
	MALTA = '+356',
	NETHERLANDS = '+31',
	POLAND = '+48',
	PORTUGAL = '+351',
	ROMANIA = '+40',
	SLOVAKIA = '+421',
	SLOVENIA = '+386',
	SPAIN = '+34',
	SWEDEN = '+46',
}

const enumToArray = (enumObj: any): string[] => Object.values(enumObj);

export const formatMobileNumber = (phone: string): string => {
	if (!phone) return '';
	const countryCodes = enumToArray(CountryCode);

	for (const code of countryCodes) {
		if (phone.startsWith(code)) {
			return `(${code}) ${phone.slice(code.length)}`;
		} else if (phone.startsWith(code.slice(1))) {
			// Handle cases where the + is missing
			return `(+${code.slice(1)}) ${phone.slice(code.length - 1)}`;
		}
	}

	return phone;
};
