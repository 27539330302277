import apiSlice, { baseQueryWithReauth } from '../app/services/apiSlice';
import prepareURL from '../common/prepareURL';

const basePath = '/contacts';
const getVariationPath = (variation) => {
	switch (variation) {
		case 'customers':
			return basePath + '/customers';
		case 'leads':
			return basePath + '/leads';
		case 'contacts':
		default:
			return basePath;
	}
};

const prepareConactsURL = (args, resource = '') => {
	const path = getVariationPath(args.variation);
	return prepareURL(path + resource, args);
};

const contactApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		contacts: builder.query({
			query: (args) => prepareConactsURL(args),
			providesTags: (result, error) => {
				const tags = [{ type: 'Contact', id: 'PARTIAL-LIST' }];

				if (!error && result?.contacts && result.contacts.length > 0) {
					const contactsTags = result.contacts.map(({ _id: id }) => ({ type: 'Contact', id }));
					return tags.concat(contactsTags);
				}

				return tags;
			},
		}),
		contactsAndTags: builder.query({
			async queryFn(args, api, extraOptions) {
				//const tags = await fetchBaseQuery(getTags, args, extraOptions, loader)();

				let result = await baseQueryWithReauth({ url: prepareConactsURL(args) }, api, extraOptions);
				if (result.isScuccess === false) {
					return result;
				}
				const contacts = result.data.contacts;
				try {
					const contactsWithTags = await injectTags({ items: contacts, api, extraOptions });
					return result;
				} catch (error) {
					return result;
				}
			},
			providesTags: (result, error) => {
				const tags = [{ type: 'Contact', id: 'PARTIAL-LIST' }];

				if (!error && result?.contacts && result.contacts.length > 0) {
					const contactsTags = result.contacts.map(({ _id: id }) => ({ type: 'Contact', id }));
					return tags.concat(contactsTags);
				}

				return tags;
			},
		}),
		contact: builder.query({
			query: (args) => prepareConactsURL(args, '/{id}'),
			providesTags: (result, error) => {
				if (!error && result?.contact) {
					return [{ type: 'Contact', id: result?.contact._id }];
				}
				return [];
			},
		}),
		changeContactType: builder.mutation({
			query: (args) => ({
				url: prepareConactsURL(args, '/{id}/type'),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: (result, error, args) => {
				const { success } = result;
				if (success && error === undefined) {
					const { id } = args.path;

					return [
						{ type: 'Contact', id },
						{ type: 'Contact', id: 'PARTIAL-LIST' },
					];
				}
			},
		}),
		changeContactDeactivation: builder.mutation({
			query: (args) => ({
				url: prepareConactsURL(args, '/{id}/deactivation'),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: (result, error, args) => {
				const { success } = result;
				if (success && error === undefined) {
					const { id } = args.path;

					return [
						{ type: 'Contact', id },
						{ type: 'Contact', id: 'PARTIAL-LIST' },
					];
				}
			},
		}),
		createContact: builder.mutation({
			query: (args) => ({
				url: prepareConactsURL(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['Contact'],
		}),
		updateContact: builder.mutation({
			query: (args) => ({
				url: prepareConactsURL(args, '/{id}'),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: (result, error, args) => {
				const { success } = result;
				if (success && error === undefined) {
					const { id } = args.path;

					return [
						{ type: 'Contact', id },
						{ type: 'Contact', id: 'PARTIAL-LIST' },
					];
				}
			},
		}),
		deleteContact: builder.mutation({
			query: (args) => ({
				url: prepareConactsURL(args, '/{id}'),
				method: 'DELETE',
			}),
			invalidatesTags: (result, error, args) => {
				const { success } = result;
				if (success && error === undefined) {
					const { id } = args.path;

					return [
						{ type: 'Contact', id },
						{ type: 'Contact', id: 'PARTIAL-LIST' },
					];
				}
			},
			refetchOn: 'fulfilled',
		}),
		searchContacts: builder.query({
			query: (args) => prepareConactsURL(args, '/search'),
		}),
	}),
});

const {
	useContactsQuery,
	useContactQuery,
	useLazyContactQuery,
	useChangeContactTypeMutation,
	useChangeContactDeactivationMutation,
	useCreateContactMutation,
	useUpdateContactMutation,
	useDeleteContactMutation,
	useContactsAndTagsQuery,
	useSearchContactsQuery,
} = contactApiSlice;

export {
	useChangeContactDeactivationMutation,
	useChangeContactTypeMutation,
	useContactQuery,
	useContactsAndTagsQuery,
	useContactsQuery,
	useCreateContactMutation,
	useDeleteContactMutation,
	useLazyContactQuery,
	useSearchContactsQuery,
	useUpdateContactMutation,
};

export const injectTags = async ({ items, api, extraOptions }) => {
	console.time('injectTags');
	let ids = items.map((item) => item._id).sort((lhs, rhs) => lhs.localeCompare(rhs));
	try {
		var tagsForItems = await baseQueryWithReauth(
			{
				url: prepareURL('/app/tags' + '/assigned', { query: { ids } }),
			},
			api,
			extraOptions
		);
		if (tagsForItems.isScuccess === false) {
			return items;
		}
		let itemsDictionary = toDictionary(items);
		var tagResponse = tagsForItems.data.result;
		tagResponse.sort((lhs, rhs) => lhs.ref_id.localeCompare(rhs.ref_id));
		tagResponse.forEach((tag) => {
			let item = itemsDictionary[tag.ref_id];
			if (!!item && !item.tags) {
				item.tags = [];
				item.tags.push(tag.tag);
			} else {
				item.tags.push(tag.tag);
			}
		});
		console.timeEnd('injectTags');
		return items;
	} catch (error) {
		return items;
	}
};

/**
 * Injects tags into items based on their IDs.
 * @param {Object} options - The options for injecting tags.
 * @param {Array} options.items - The items to inject tags into.
 * @param {Object} options.api - The rtl query api object for making requests.
 * @param {Object} options.extraOptions - Extra options for the API request.
 * @returns {Promise<Array>} The items with injected tags.
 */
const injectTagsOpt = async ({ items, api, extraOptions }) => {
	console.time('injectTags');
	let ids = items.map((item) => item._id).sort((lhs, rhs) => lhs.localeCompare(rhs));
	var tagsForItems = await baseQueryWithReauth(
		{
			url: prepareURL('/app/tags' + '/assigned', { query: { ids } }),
		},
		api,
		extraOptions
	);
	if (tagsForItems.isScuccess === false) {
		return items;
	}
	var tagResponse = tagsForItems.data.result;
	tagResponse.sort((lhs, rhs) => lhs.ref_id.localeCompare(rhs.ref_id));
	tagResponse.forEach((tag) => {
		let item = items.find((_item) => _item._id === tag.ref_id);
		if (!item.tags) {
			item.tags = [];
			item.tags.push(tag.tag);
		} else {
			item.tags.push(tag.tag);
		}
	});
	console.timeEnd('injectTags');
	return items;
};

function toDictionary(arr) {
	let dict = {};
	for (let obj of arr) {
		if (obj._id) {
			dict[obj._id] = obj;
		}
	}
	return dict;
}
