import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

const initialValues = {
	firstname: '',
	lastname: '',
	email: '',
	password: '',
	changepassword: '',
	acceptTerms: false,
};

const registrationSchema = Yup.object().shape({
	firstname: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('First name is required'),
	email: Yup.string().email('Wrong email format').min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Email is required'),
	lastname: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Last name is required'),
	password: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Password is required'),
	changepassword: Yup.string().required('Password confirmation is required'),
	acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
});

export function Registration() {
	const {
		register,
		handleSubmit,
		formState: { errors, isValid, isSubmitting, touchedFields },
	} = useForm({
		defaultValues: initialValues,
		mode: 'all',
		resolver: yupResolver(registrationSchema),
	});

	const isLoading = false,
		isError = false;
	const registration = () => {};
	// const [registration, { isLoading, isError }] = useRegistrationMutation();

	const onSubmit = ({ firstname, lastname, email, password, changepassword, acceptTerms }) => {
		const args = {
			firstname,
			lastname,
			email,
			password,
			changepassword,
			acceptTerms,
		};

		registration(args);
	};

	return (
		<div className="card card-custom overlay min-h-125px shadow-none">
			<div className="card-body d-flex flex-column p-10">
				<form
					className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
					noValidate
					id="kt_login_signup_form"
					onSubmit={handleSubmit(onSubmit)}
				>
					<div className="text-center mb-11">
						<h1 className="text-dark fw-bolder mb-3"><FormattedMessage id="AUTH.REGISTER.TITLE" /></h1>
					</div>

					{isError && (
						<div className="mb-lg-15 alert alert-danger">
							<div className="alert-text font-weight-bold"><FormattedMessage id="AUTH.REGISTER.INCORRECT.DETAILS" /></div>
						</div>
					)}

					<div className="fv-row mb-8">
						<label className="form-label fw-bolder text-dark fs-6"><FormattedMessage id="AUTH.INPUT.FNAME" /></label>
						<input
							placeholder="First name"
							type="text"
							autoComplete="off"
							{...register('firstname')}
							className={clsx(
								'form-control bg-transparent',
								{ 'is-invalid': touchedFields.firstname && errors.firstname },
								{ 'is-valid': touchedFields.firstname && !errors.firstname }
							)}
						/>
						{errors.firstname && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{errors.firstname.message}</span>
								</div>
							</div>
						)}
					</div>

					<div className="fv-row mb-8">
						<label className="form-label fw-bolder text-dark fs-6"><FormattedMessage id="AUTH.INPUT.LNAME" /></label>
						<input
							placeholder="Last name"
							type="text"
							autoComplete="off"
							{...register('lastname')}
							className={clsx(
								'form-control bg-transparent',
								{ 'is-invalid': touchedFields.lastname && errors.lastname },
								{ 'is-valid': touchedFields.lastname && !errors.lastname }
							)}
						/>
						{errors.lastname && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{errors.lastname.message}</span>
								</div>
							</div>
						)}
					</div>

					<div className="fv-row mb-8">
						<label className="form-label fw-bolder text-dark fs-6"><FormattedMessage id="AUTH.INPUT.EMAIL" /></label>
						<input
							placeholder="Email"
							type="email"
							autoComplete="off"
							{...register('email')}
							className={clsx(
								'form-control bg-transparent',
								{ 'is-invalid': touchedFields.email && errors.email },
								{ 'is-valid': touchedFields.email && !errors.email }
							)}
						/>
						{errors.email && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{errors.email.message}</span>
								</div>
							</div>
						)}
					</div>

					<div className="fv-row mb-8" data-kt-password-meter="true">
						<div className="mb-1">
							<label className="form-label fw-bolder text-dark fs-6"><FormattedMessage id="AUTH.INPUT.PASSWORD" /></label>
							<div className="position-relative mb-3">
								<input
									type="password"
									placeholder="Password"
									autoComplete="off"
									{...register('password')}
									className={clsx(
										'form-control bg-transparent',
										{ 'is-invalid': touchedFields.password && errors.password },
										{ 'is-valid': touchedFields.password && !errors.password }
									)}
								/>
								{errors.password && (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">
											<span role="alert">{errors.password.message}</span>
										</div>
									</div>
								)}
							</div>

							<div className="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
								<div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
								<div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
								<div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
								<div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
							</div>
						</div>
						<div className="text-muted"><FormattedMessage id="AUTH.REGISTER.PASSWORD.RULES" /></div>
					</div>

					<div className="fv-row mb-5">
						<label className="form-label fw-bolder text-dark fs-6"><FormattedMessage id="AUTH.INPUT.CONFIRM_PASSWORD" /></label>
						<input
							type="password"
							placeholder="Password confirmation"
							autoComplete="off"
							{...register('changepassword')}
							className={clsx(
								'form-control bg-transparent',
								{ 'is-invalid': touchedFields.changepassword && errors.changepassword },
								{ 'is-valid': touchedFields.changepassword && !errors.changepassword }
							)}
						/>
						{errors.changepassword && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{errors.changepassword.message}</span>
								</div>
							</div>
						)}
					</div>

					<div className="fv-row mb-8">
						<label className="form-check form-check-inline" htmlFor="kt_login_toc_agree">
							<input className="form-check-input" type="checkbox" id="kt_login_toc_agree" {...register('acceptTerms')} />
							<span>
								I Accept the{' '}
								<a href="https://keenthemes.com/metronic/?page=faq" target="_blank" rel="noreferrer" className="ms-1 link-primary">
									Terms
								</a>
								.
							</span>
						</label>
						{errors.acceptTerms && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{errors.acceptTerms.message}</span>
								</div>
							</div>
						)}
					</div>

					<div className="text-center">
						<button
							type="submit"
							id="kt_sign_up_submit"
							className="btn btn-lg btn-primary w-100 mb-5"
							// !acceptTerms
							disabled={isSubmitting || !isValid}
						>
							{!isLoading && <span className="indicator-label"><FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" /></span>}
							{isLoading && (
								<span className="indicator-progress" style={{ display: 'block' }}>
									<FormattedMessage id="AUTH.LOGIN.LOADING" /> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
								</span>
							)}
						</button>
						<Link to="/auth/login">
							<button type="button" id="kt_login_signup_form_cancel_button" className="btn btn-lg btn-light-primary w-100 mb-5">
							<FormattedMessage id="GENERAL.CANCEL" />
							</button>
						</Link>
					</div>
				</form>
			</div>
		</div>
	);
}
