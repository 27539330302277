import clsx from 'clsx';

import { KTSVG } from '../../../helpers';
import { HeaderStoreMenu, HeaderUser, HeaderUserMenu, Search } from '../../../partials';
import { useLayout } from '../../core';

const itemClass = 'ms-1 ms-lg-3';
const btnClass = 'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px';
const btnIconClass = 'svg-icon-1';

const Navbar = () => {
	const { config } = useLayout();
	return (
		<div className="app-navbar flex-shrink-0">
			{/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
				<Search />
			</div>

			<div className={clsx('app-navbar-item', itemClass)}>
				<div data-kt-menu-trigger="{default: 'click'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end" className={btnClass}>
					<KTSVG path="/media/icons/duotune/general/gen007.svg" className={btnIconClass} />
				</div>
			</div> */}

			<div className={clsx('app-navbar-item d-none d-sm-flex', itemClass)}>
				<div data-kt-menu-trigger="{default: 'click'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end" className={btnClass}>
					<KTSVG path="/media/icons/duotune/ecommerce/ecm004.svg" className={btnIconClass} />
				</div>
				<HeaderStoreMenu />
			</div>

			<div className={clsx('app-navbar-item d-none d-sm-flex', itemClass)}>
				<HeaderUser />
				<HeaderUserMenu />
			</div>

			{config.app?.header?.default?.menu?.display && (
				<div className="app-navbar-item d-lg-none ms-2 me-n3" title="Show header menu">
					<div className="btn btn-icon btn-active-color-primary w-35px h-35px" id="kt_app_header_menu_toggle">
						<KTSVG path="/media/icons/duotune/text/txt001.svg" className={btnIconClass} />
					</div>
				</div>
			)}
		</div>
	);
};

export { Navbar };
