import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';

import useConfirmationModal from './useConfirmationModal';
import useModal from './useModal';

const ModalFooter = () => {
	const [confirmAction, confirmationModal] = useConfirmationModal();
	const { id, editMode, onSubmitForm, confirmedAction, onClose } = useModal();

	const handleDeleteConfirmation = (confirmed) => {
		confirmedAction(id);
		onClose();
	};

	return (
		<div className={clsx('modal-footer ', { 'd-flex justify-content-between': editMode })}>
			{editMode ? confirmationModal : null}
			{editMode ? (
				<button
					type="button"
					className="btn btn-light-danger btn-color-danger"
					onClick={() => confirmAction(() => handleDeleteConfirmation())}
				>
					<FormattedMessage id="GENERAL.DELETE" />
				</button>
			) : null}
			<div>
				<button type="button" className="generic-button fw-bold mx-5 fs-5" onClick={onClose}>
					<FormattedMessage id="GENERAL.CANCEL" />
				</button>
				<button type="button" className="btn btn-primary" onClick={onSubmitForm}>
					{editMode ? 'Save' : 'Create'}
				</button>
			</div>
		</div>
	);
};

export default ModalFooter;
