export enum HostViewEnum {
	Events = 'events',
	WaitList = 'waitlist',
	Reservation = 'reservations',
}

export enum BtnClassEnum {
	Generic = 'generic-button',
	Primary = 'primary-button',
	Secondary = 'secondary-button',
	Delete = 'delete-button',
	White = 'white-button',
	Black = 'black-button',
	Gray = 'gray-button',
}

export enum StatusEnum {
	Available = 'Available', // Free
	Reserved = 'Reserved', // Arrived -> Confirmed
	Occupied = 'Occupied', // Completed
}

export enum GeneralClassEnum {
	BG_White = 'bg-white',
	White_Text = 'white-text',
	White_Background = 'white-background',
}

export enum InputType {
	Text = 'text',
	Password = 'password',
	Email = 'email',
	Number = 'number',
	Tel = 'tel',
	Url = 'url',
	Search = 'search',
	Date = 'date',
	DateTimeLocal = 'datetime-local',
	Month = 'month',
	Week = 'week',
	Time = 'time',
	Color = 'color',
	Checkbox = 'checkbox',
	Radio = 'radio',
	Range = 'range',
	File = 'file',
	Hidden = 'hidden',
	Button = 'button',
	Submit = 'submit',
	Reset = 'reset',
	Image = 'image',
}
