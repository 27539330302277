import clsx from 'clsx';
import { memo, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

const MergedTableDropdown = ({ openDropdownValue, onOpenDropdownChange, tableRef, onTableSelection, tables, editTables, isReservationSuccess }) => {
	const { watch, control } = useForm({
		defaultValues: { table: [{ type: tables[0] }] },
	});

	const {
		fields: tableFields,
		append: appendTable,
		remove: removeTable,
	} = useFieldArray({
		name: 'table',
		control,
	});

	const selectedTables = watch('table');

	const [currentOpenDropdown, setCurrentOpenDropdown] = useState(-1);

	useEffect(() => {
		onOpenDropdownChange(openDropdownValue);
		onTableSelection(selectedTables);
	}, [onOpenDropdownChange, onTableSelection, openDropdownValue, selectedTables]);

	const toggleTableDropdown = (index) => {
		if (currentOpenDropdown === index) {
			setCurrentOpenDropdown(-1);
			onOpenDropdownChange('');
		} else {
			setCurrentOpenDropdown(index);
			onOpenDropdownChange(`table_${index}`);
		}
	};

	useEffect(() => {
		if (
			openDropdownValue === '' ||
			openDropdownValue === 'date' ||
			openDropdownValue === 'time' ||
			openDropdownValue === 'store' ||
			openDropdownValue === 'partySize' ||
			openDropdownValue === 'source' ||
			openDropdownValue === 'status' ||
			openDropdownValue === 'duration' ||
			openDropdownValue === 'phoneNumber' ||
			openDropdownValue === 'hiddenPhoneNumber'
		) {
			setCurrentOpenDropdown(-1);
		}
	}, [openDropdownValue]);

	const getAvailableOptions = (currentIndex) => {
		return tables.filter((option) => !selectedTables.some((table, idx) => table.type === option && idx !== currentIndex));
	};

	const getNextAvailableOption = () => {
		const selected = [...selectedTables].map((t) => t.type);
		const unselectedTables = tables.filter((option) => !selected.includes(option));
		if (unselectedTables.length) return unselectedTables[0];
		return '';
	};

	// Edit Mode

	const [manualTableFields, setManualTableFields] = useState([]);

	// Initialize manualTableFields when the component mounts or editTables changes
	useEffect(() => {
		if (isReservationSuccess && editTables.length > 0) {
			setManualTableFields(editTables.map((table) => ({ type: table })));
		}
	}, [isReservationSuccess, editTables]);

	// Synchronize manualTableFields with useFieldArray
	useEffect(() => {
		// Remove all existing fields first
		if (manualTableFields.length > 0) {
			for (let i = tableFields.length - 1; i >= 0; i--) {
				removeTable(i);
			}

			// Append new fields from manualTableFields
			manualTableFields.forEach((field) => {
				appendTable(field);
			});
		}
	}, [manualTableFields, removeTable, appendTable]);

	return (
		<div className="p-5 generic-border">
			{tableFields.map((fieldTable, index) => {
				return (
					<div className="row" key={fieldTable.id}>
						<div className="col-12">
							<Controller
								name={`table[${index}].type`}
								control={control}
								defaultValue={fieldTable.type}
								render={({ field }) => (
									<div className="row pb-5">
										<div className={clsx('col-12 col-sm-5')}>
											<div className="pb-3 fs-7 fw-bold">
												<FormattedMessage id="RESERVATION.MERGED_TABLE.ASSIGNED_TABLE" />
											</div>
											<div className={clsx('custom-select', { open: currentOpenDropdown === index })}>
												{/* <div className="custom-select-icon icon-left">
												<i className="fa-solid fa-calendar" />
											</div> */}
												<div className="select-wrapper">
													<div
														className="select-header icon-left p-4"
														onClick={() => toggleTableDropdown(index)}
														ref={tableRef[index]}>
														<span className="selected-option fs-6 fw-bold">{field.value}</span>
														<i className="fa-solid fa-chevron-down arrow-icon" />
													</div>
													<ul className={clsx('select-options fs-6 fw-bold', { 'd-none': currentOpenDropdown !== index })}>
														{getAvailableOptions(index).map((tableOption) => (
															<li
																key={tableOption}
																className={clsx('option', { active: field.value === tableOption })}
																onClick={() => {
																	field.onChange(tableOption);
																	setCurrentOpenDropdown(-1);
																}}>
																{tableOption}
															</li>
														))}
													</ul>
												</div>
												<div className={clsx('custom-select-icon icon-right', { 'd-none': tableFields.length < 2 })}>
													<a href="#" onClick={() => removeTable(index)}>
														<i className="fa-solid fa-trash-can text-danger" />
													</a>
												</div>
											</div>
										</div>
									</div>
								)}
							/>
						</div>
					</div>
				);
			})}
			{tableFields.length < tables.length && (
				<div className="row">
					<div className="col-12 col-sm-5">
						<button
							type="button"
							className="secondary-button fw-bold w-100 fs-6 p-4"
							onClick={() => {
								appendTable({ type: getNextAvailableOption() });
							}}>
							<FormattedMessage id="RESERVATION.MERGED_TABLE.MERGE_TABLE" />
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default memo(MergedTableDropdown);
