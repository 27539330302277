import { combineReducers, configureStore } from '@reduxjs/toolkit';
import appSlice from 'src/reducers/appSlice';

import { devMode } from '../helpers/utils';
import authReducer from '../reducers/auth/authSlice';
import businessReducer from '../reducers/business/businessSlice';
import hostViewReducer from '../reducers/host-view/slice';
import modalFormReducer from '../reducers/modalFormSlice';
import modalReducer from '../reducers/modalSlice';
import toolbarReducer from '../reducers/toolbarSlice';
import customMiddlewares from './middleware';
import apiSlice from './services/apiSlice';

const rootReducer = combineReducers({
	[apiSlice.reducerPath]: apiSlice.reducer,
	auth: authReducer,
	modals: modalReducer,
	modalForms: modalFormReducer,
	hostView: hostViewReducer,
	toolbar: toolbarReducer,
	business: businessReducer,
	app: appSlice,
});

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat(apiSlice.middleware, customMiddlewares),
	devTools: devMode,
});

export default store;
