import clsx from 'clsx';
import { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { useGetBusinessByRoleQuery } from '../../../../../reducers/business/businessApiSlice';
import {
	selectActiveBusiness,
	selectActiveStore,
	selectBusinesses,
	setActiveBusiness,
	setActiveBusinessStore,
} from '../../../../../reducers/business/businessSlice';
import { MenuButton } from '../../header/header-menus/MenuButton';
import { MenuInnerWithSub } from '../../header/header-menus/MenuInnerWithSub';
import { MenuItem } from '../../header/header-menus/MenuItem';
import SidebarMenuMain from './SidebarMenuMain';

const SidebarMenu = () => {
	const dispatch = useDispatch();
	const intl = useIntl();

	const businesses = useSelector(selectBusinesses);
	const hasBusinesses = Array.isArray(businesses) && businesses.length > 0;

	const activeStore = useSelector(selectActiveStore);
	const activeBusiness = useSelector(selectActiveBusiness);

	const { isSuccess } = useGetBusinessByRoleQuery({ skip: hasBusinesses });

	const [clickedBusiness, setClickedBusiness] = useState(null);

	const handleChangeStore = () => {
		const selectedBusiness = businesses.find((b) => b._id === activeBusiness._id);
		const selectedStore = selectedBusiness.stores[0];

		dispatch(
			setActiveBusinessStore({
				store: {
					_id: selectedStore._id!,
					title: selectedStore.title,
				},
				business: {
					_id: selectedBusiness._id!,
					title: selectedBusiness.title,
					logo: selectedBusiness.logo,
				},
			})
		);
	};

	const handleChangeActiveBusiness = (business) => {
		setClickedBusiness(business);

		dispatch(
			setActiveBusiness({
				business: {
					_id: business._id!,
					title: business.title,
					logo: business.logo,
				},
			})
		);
	};

	useEffect(() => {
		if ((clickedBusiness as any)?._id === activeBusiness?._id) {
			handleChangeStore();
		}
		return () => {
			setClickedBusiness(null);
		};
	}, [activeBusiness, clickedBusiness]);

	return (
		<>
			<div
				className="app-sidebar-business-menu menu menu-column menu-rounded menu-sub-indention px-3 my-2 overflow-hidden"
				id="#kt_app_sidebar_menu"
				data-kt-menu="true"
				data-kt-menu-expand="false"
			>
				{isSuccess && hasBusinesses ? (
					<MenuInnerWithSub
						title={`${activeBusiness.title} (${activeStore.title})`}
						to="/business"
						menuPlacement="bottom-start"
						menuTrigger="click"
						classname="sidebar-button my-2 btn btn-primary px-3"
						image={activeBusiness?.logo?.url}
					>
						{businesses.map((business) => (
							<Fragment key={business._id}>
								<div className="sidebar-business-container">
									<MenuButton
										title={business.title}
										classname={clsx('fw-bold', { active: business._id === activeBusiness._id })}
										onClick={() => handleChangeActiveBusiness(business)}
									/>
									{/* <label className={clsx('fs-6 p-2', { active: business._id === activeBusiness._id })}>{business.title}</label> */}
									{/* <div className="dropdown-divider" />
									{business?.stores.map((store) => (
										<MenuButton
											key={store._id}
											title={store.title}
											classname={clsx('fw-bold', { active: store._id === activeStore._id })}
											onClick={() => handleChangeStore(business, store)}
										/>
									))} */}
								</div>
							</Fragment>
						))}

						<MenuItem
							title={intl.formatMessage({ id: 'MENU.BUSINESSES.NEW_ORGANIZATION' })}
							to="/settings/business"
							fontIcon="bi-plus-square-fill"
						/>
					</MenuInnerWithSub>
				) : (
					<MenuItem
						title={intl.formatMessage({ id: 'MENU.BUSINESSES.NEW_ORGANIZATION' })}
						to="/settings/business"
						fontIcon="bi-plus-square-fill"
						classes="active"
					/>
				)}
			</div>

			<div className="sidebar-seperator" />

			<div className="app-sidebar-menu overflow-x overflow-hidden">
				<div
					id="kt_app_sidebar_menu_wrapper"
					className="app-sidebar-wrapper hover-scroll-overlay-y"
					data-kt-scroll="true"
					data-kt-scroll-activate="true"
					data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
					data-kt-scroll-wrappers="#kt_app_sidebar_menu"
					data-kt-scroll-offset="5px"
					data-kt-scroll-save-state="true"
				>
					<div
						className="menu menu-column menu-rounded menu-sub-indention px-3"
						id="#kt_app_sidebar_menu"
						data-kt-menu="true"
						data-kt-menu-expand="false"
					>
						<SidebarMenuMain />
					</div>
				</div>
			</div>
		</>
	);
};

export default SidebarMenu;
