import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getSessionStorage } from 'src/common/storage';

const HeaderUser = () => {
	const currentUser = JSON.parse(getSessionStorage('user'));
	const userAvatarClass = 'symbol-35px symbol-md-40px';

	const acronym = currentUser?.name.match(/\b(\w)/g).join('') ?? '';

	return (
		<div
			className={clsx(
				'circle-border d-flex align-items-center justify-content-center cursor-pointer symbol bg-primary',
				userAvatarClass
			)}
			data-kt-menu-trigger="{default: 'click'}"
			data-kt-menu-attach="parent"
			data-kt-menu-placement="bottom-end">
			<span className="text-white fw-bold fs-4">{acronym}</span>
		</div>
	);
};

export { HeaderUser };
